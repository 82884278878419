import React, { useEffect, useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Dialog, Tab, Tabs, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import PTRestAPI from '../../utils/pt_rest_api'
import {
  accountSelector,
  subscriptionSelector,
  useAuth,
  useLogout,
  userProfileSelector,
} from 'src/utils/platform_auth'
import { getApiUrl } from '../../utils/routing.js'
import { Navigate, useNavigate, useParams } from 'react-router'
import {
  AppleStoreButton,
  GoogleStoreButton,
  PlayNowButton,
} from '../../components/StoreButtons'
import axios from 'axios'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTheme } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  atom,
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilState,
} from 'recoil'
import { durationToText, useTranslation } from '../../utils/localization.js'
import { recoilPersist } from 'recoil-persist'
import StaticImg from '../../components/StaticImg/index.jsx'

function CancelSubscription({ open, onClosed }) {
  const auth = useAuth()
  const { contents: userProfile } = useRecoilValueLoadable(userProfileSelector)
  const nameInputRef = useRef()
  const [waitingCancelSub, setWaitingCancelSub] = useState(false)
  const [error, setError] = useState('')
  const t = useTranslation()
  const apiUrl = getApiUrl()

  useEffect(() => {
    if (open) setError('')
  }, [open])

  const defaultName = t`Aldoo User` // @@ Default User Name

  async function cancelSubscription() {
    // @ Test 1
    if (
      nameInputRef.current.value !== (userProfile.name.trim() || defaultName)
    ) {
      setError(t`Incorrect value`) // @@ Error message
      return
    }

    setWaitingCancelSub(true)
    try {
      await axios.post(`${apiUrl}/payment-gateway/cancel-subscription`, {
        credentials: auth,
      })
    } catch (e) {
      console.log(e.message)
    }
    setWaitingCancelSub(false)

    location.reload()
  }

  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title">
        {t`Cancel your subscription?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ marginBottom: '0.5rem' }}
        >
          {
            t(
              `Do you really want to cancel your subscription? To confirm please type in
            your account name %username%`,
              userProfile?.name ?? defaultName,
            ) /* @Cancel Subscription Confirmation */
          }
        </DialogContentText>
        <TextField
          inputRef={nameInputRef}
          autoFocus
          margin="dense"
          label={t`Your Account Name`}
          type="text"
          fullWidth
          variant="standard"
          error={!!error}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={waitingCancelSub}
          onClick={cancelSubscription}
          variant="action-color"
          color="1"
        >
          {t`Confirm`}
        </LoadingButton>
        {!waitingCancelSub && (
          <Button onClick={onClosed} variant="action-color">
            {t`Close`}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default function ActiveSubscription({ subscription }) {
  const t = useTranslation()
  const theme = useTheme()
  const [confirmCancel, setConfirmCancel] = useState(false)

  return (
    <Stack //------------------------------------------------------>Subscription (Left Side)
      sx={{
        width: { xs: '100%', sm: '45%' },
        height: '280px',
        justifyContent: 'flex-start',
        alignItems: { xs: 'center', sm: 'center' },
      }}
    >
      <Typography variant="h4" sx={{ mb: '20px' }}>
        {t`Subscription:`}
      </Typography>

      <Typography 
      variant="h4"
      component="h5"
        sx={{ 
          fontSize: '16px',
          textAlign: { 
            xs: 'center', 
            sm: 'right', 
            mb: '10px',
          },
        }}
      >
        {t`Personal Plan`}
      </Typography>

      {/* ------------------------------coins and value */}  
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          gap: '18px',
        }}
      >
        <Stack direction="column" sx={{ alignItems: 'center' }}>
          <Stack direction="row" sx={{ alignItems: 'center' }} gap={1}>
            <StaticImg alt="aldoo-credits" src="coin.png" crop="60x60" />
            <Typography 
              variant="accent_number"
            >
              {subscription.amount}
            </Typography>
          </Stack>

          <Typography 
            variant="subtitle"
            sx={{
              fontSize: '16px',
            }}
          >
            {t(
              'per %period%',
              durationToText(t, subscription.subscription.duration),
            )}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        sx={{
          alignItems: 'center',
          gap: { xs: '8px' },
          mt: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40px',
            width: '190px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '20px',
            backgroundColor: theme.colors.aldoo.black,
            px: '16px',
          }}
        >
          <Typography
            variant="button"
            sx={{
              fontSize: '18px',
              fontWeight: 600,
              color: theme.colors.aldoo.white,
              textTransform: 'none',
            }}
          >
            {t`Active`}
          </Typography>
        </Box>

        <Link href="#" underline="hover" onClick={() => setConfirmCancel(true)}>
          <Typography 
            variant="subtitle3"
            color="primary"
            fontSize={"14px"}
          >
            {t`Unsubscribe`}
          </Typography>
        </Link>
      </Stack>
      <CancelSubscription
        open={confirmCancel}
        onClosed={() => setConfirmCancel(false)}
      />
    </Stack>
  )
}
