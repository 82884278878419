import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import StaticImg from "../../components/StaticImg/index.jsx"
import Logo from '../../components/Logo'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from '../../utils/localization.js'

export default function LicenseCard({ license }) {
  const t = useTranslation()
  const theme = useTheme()

  return (
    <Stack
      sx={{
        width: {
          xs: "328px",
          sm: "440px",
        },
        height: {
          xs: "180px",
          sm: "233px",
        },
        justifyContent: "flex-start",
        alignItems: "flex-start",
        backgroundImage: "url(/static/images/license_card-image.webp)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxShadow: "5px 5px 15px rgba(0,0,0,0.30)",
        borderRadius: "10px",
        p: {
          xs: "10px",
          sm: "16px",
        },
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Stack
          sx={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Logo
            sx={{
              width: { xs: "100px", sm: "132px" },
              height: { xs: "20px", sm: "26px" },
            }}
          />

          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: "16px", sm: "20px" },
              color: theme.colors.primary.main,
              ml: "6px",
              mt: { xs: "-10px", sm: "-5px" },
            }}
          >
            KIDS
          </Typography>
        </Stack>

        <Stack
          sx={{
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Typography
            variant="subtitle"
            sx={{
              color: theme.colors.aldoo.white,
              fontSize: { xs: "16px", sm: "20px" },
              fontWeight: 700,
              lineHeight: 1,
            }}
          >
            {t`License:`}
          </Typography>

          <Typography
            variant="subtitle"
            sx={{
              color: theme.colors.aldoo.white,
              fontSize: { xs: "16px", sm: "20px" },
              lineHeight: 1.2,
            }}
          >
            {license?.code}
          </Typography>
        </Stack>
      </Stack>

      <Typography
        variant="h4"
        sx={{
          color: theme.colors.aldoo.black,
          fontSize: {
            xs: 10,
            sm: 12,
          },
          fontWeight: 700,
          textTransform: "uppercase",
          textAlign: "left",
          mt: "auto",
        }}
      >
        {t`A Learning Platform For Children 3-7 y.o.`}
      </Typography>
    </Stack>
  )
}

