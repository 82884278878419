import { getImageSize } from "../Img/index.jsx";
import { PLAYER_SAFE_AREA_PERCENT } from "../../config.js";

const fallbackLayouts = {
  tablet: ["web", "phone"],
  phone: ["tablet", "web"],
  web: ["tablet", "phone"]
}

export function getFallbackLayouts(layout) {
  return fallbackLayouts[layout]
}

export function getLayout(viewport) {
  const aspect = viewport.width / viewport.height
  if (aspect >= 1.5)
    return 'phone'

  return 'tablet'
}

function getPageTransform(background, phase, layout) {

  const [layout1, layout2] = getFallbackLayouts(layout)
  const phaseField = phase + "Transform"
  const transform = background[layout][phaseField]
  const transform1 = background[layout1][phaseField]
  const transform2 = background[layout2][phaseField]

  let x = 0
  let y = 0
  let angle = 0
  let scale = 1
  if (transform) {
    x = transform.offsetPos?.x || 0
    y = transform.offsetPos?.y || 0
    scale = transform.scale !== undefined ? transform.scale : 1
    angle = transform.angle !== undefined ? transform.angle : 0
  } else if (transform1) {
    x = transform1.offsetPos?.x || 0
    y = transform1.offsetPos?.y || 0
    scale = transform1.scale !== undefined ? transform1.scale : 1
    angle = transform1.angle !== undefined ? transform1.angle : 0
  } else if (transform2) {
    x = transform2.offsetPos?.x || 0
    y = transform2.offsetPos?.y || 0
    scale = transform2.scale !== undefined ? transform2.scale : 1
    angle = transform2.angle !== undefined ? transform2.angle : 0
  }

  return {
    offsetPos: {
      x: x || 0,
      y: y || 0
    },
    scale: scale || 1,
    angle: angle || 0,
    verticalFocus:
      background[layout].verticalFocus ||
      background[layout1].verticalFocus ||
      background[layout2].verticalFocus ||
      0.5
  }
}

export function getPageSubtitles(subtitle, layout) {

  const [layout1, layout2] = getFallbackLayouts(layout)

  const subtitle1 = subtitle[layout]
  const subtitle2 = subtitle[layout1]
  const subtitle3 = subtitle[layout2]

  const result = {}

  for (const t of [subtitle1, subtitle2, subtitle3]) {
    for (const key in t) {
      if (!(key in result)) {
        result[key] = t[key]
      }
    }
  }

  result.renderBox = {
    left: subtitle1.renderBox.left || subtitle2.renderBox.left || subtitle3.renderBox.left,
    top: subtitle1.renderBox.top || subtitle2.renderBox.top || subtitle3.renderBox.top,
    width: subtitle1.renderBox.width || subtitle2.renderBox.width || subtitle3.renderBox.width,
    height: subtitle1.renderBox.height || subtitle2.renderBox.height || subtitle3.renderBox.height,
  }

  return result
}

function getVerticalFocusOffset(viewport, verticalFocus) {
  const normalizedHeight = viewport.height / viewport.width * 1000
  return (verticalFocus * 2 - 1) * (1000 - normalizedHeight)
}

export function getAnimDuration(background, layout) {
  const [layout1, layout2] = getFallbackLayouts(layout)
  return background[layout].duration || background[layout1].duration || background[layout2].duration || 0
}

export function getPageBackground(background, layout) {

  if (background[layout].image)
    return [background[layout].image, layout]
  const [layout1, layout2] = getFallbackLayouts(layout)

  if (background[layout1].image)
    return [background[layout1].image, layout1]

  if (background[layout2].image)
    return [background[layout2].image, layout2]

  return []
}

export function getSubtitleAudioDuration(subtitle, layout) {
  if (subtitle[layout].audio)
    return subtitle[layout].audioDuration
  const [layout1, layout2] = getFallbackLayouts(layout)

  if (subtitle[layout1].audio)
    return subtitle[layout1].audioDuration

  if (subtitle[layout2].audio)
    return subtitle[layout2].audioDuration

  return 0
}

export function getSubtitleAudio(subtitle, layout) {
  if (subtitle[layout].audio)
    return subtitle[layout].audio
  const [layout1, layout2] = getFallbackLayouts(layout)

  if (subtitle[layout1].audio)
    return subtitle[layout1].audio

  if (subtitle[layout2].audio)
    return subtitle[layout2].audio
}

export function backgroundTransform(viewport, background, phase, layout) {
  const transform = getPageTransform(background, phase, layout)
  const [image] = getPageBackground(background, layout)
  const { width: imageWidth, height: imageHeight } = getImageSize(image)
  const focusOffset = getVerticalFocusOffset(viewport, transform.verticalFocus)


  const r = viewport.width / imageWidth
  const left = r * transform.offsetPos.x + viewport.width / 2 - imageWidth / 2 * r * transform.scale
  const bottom = r * transform.offsetPos.y + viewport.height / 2 - imageHeight / 2 * r * transform.scale + r * focusOffset
  return {
    left,
    bottom,
    width: r * imageWidth * transform.scale,
    height: r * imageHeight * transform.scale,
    angle: transform.angle,
  }
}

export function subtitlesTransform(viewport, subtitle, background, phase, layout) {
  const transform = getPageTransform(background, phase, layout)
  const [image] = getPageBackground(background, layout)
  const focusOffset = getVerticalFocusOffset(viewport, transform.verticalFocus)
  const { width: imageWidth, height: imageHeight } = getImageSize(image)
  const scale = viewport.width / 1000
  const { text, color, alignment, font, fontSize, renderBox } = getPageSubtitles(subtitle, layout)

  const height = "auto" //renderBox.height * scale
  const safeSize = viewport.height * PLAYER_SAFE_AREA_PERCENT / 100

  let top = viewport.height / 2 - renderBox.top * scale + focusOffset * scale
  if (top < safeSize)
    top = safeSize
  else if (top + height > viewport.height - safeSize)
    top = viewport.height - safeSize - height

  return {
    text,
    color,
    alignment,
    font,
    fontSize: `${fontSize * scale}px`,
    renderBox: {
      left: renderBox.left * scale + viewport.width / 2,
      top,
      width: renderBox.width * scale,
      height
    }
  }
}
