/**
 * This file represents that Aldoo Analytics client.
 */
import axios from "axios"
import moment from "moment"

export class LicenseCards {

    static raid
    static maid
    static muid
    static API_URL

    /**
     * Submit only valid values
     * @param {*} payload
     * @returns
     */
    static sanitize(payload) {
        const result = {}
        for (let key in payload) {
            const value = payload[key]
            //Empty string values
            if (value && !(typeof (value) == "string" && value.length == 0)) {
                result[key] = value
            }
        }
        return result
    }

    /**
     * Construct the endpoint url for the API
     * @param {*} endPointName
     * @returns
     */
    static ep(endPointName) {
        return `${this.API_URL}/v1/${endPointName}`
    }
    /**
     * @param {*} settings
     * @returns
     */
    static init({ API_URL, maid, muid, raid, lcid }) {
        //setup the static variables
        this.API_URL = API_URL;
        this.maid = maid
        this.muid = muid
        this.raid = raid
        this.lcid = lcid
    }

    static async listLicenses() {
        let response = await axios.post(this.ep('license-card'), {
            maid: this.maid,
            muid: this.muid,
            list: true
        });
        //get the response data
        response = response.data

        return response
    }

    /**
     * Hit the touch endpoint so that we bind the IP for that license card
     */
    static async touch() {
        let response = await axios.post(this.ep('license-card'), {
            lcid: this.lcid,
            touch: true
        });
        //get the response data
        response = response.data

        return response
    }

    static async getPairingStatus() {

        let response = await axios.post(this.ep('license-card'), {
            maid: this.maid,
            pair_status: true,
            lcid: this.lcid
        });
        //get the response data
        response = response.data

        return response
    }

    static async ensureLicense() {
        let response = await axios.post(this.ep('license-card'), {
            maid: this.maid,
            raid: this.raid,
            muid: this.muid,
            ensure: true
        });
        //get the response data
        response = response.data

        return response
    }
}