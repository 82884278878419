import PTRestAPI from './pt_rest_api'
import { useRef, useState } from 'react'
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil'
import { sleep } from './time'
import { currentLanguageAtom } from './localization.js'

export const getErrorMessages = (error) => {
  switch (error) {
    case 'invalid_user':
      return "This account doesn't exist"
    case 'free_user':
      return "This account doesn't exist"
    case 'invalid_device':
      return 'There was a problem registering. Please try reinstalling the application'
    case 'magic_link_generate_error':
      return 'There was a problem verifying your email.\nThe support was notified and will resolve it shortly.'
    case 'invalid_credentials':
      return 'Invalid credentials'
    case 'user_exists':
      return 'Email is already registered'
  }
  return error
}

const CREDENTIALS_KEY = 'pt-dck'
const CURRENT_LOCAL_PURCHASE_KEY = 'pt-clp'

const readCurrentLocalPurchase = () => {
  try {
    const data = localStorage.getItem(CURRENT_LOCAL_PURCHASE_KEY)
    return data ? JSON.parse(data) : {}
  } catch {
    return {}
  }
}

//This atom was created so the pid, price and currency can be used in the success page
//That way we can report a plan purchase to the tracking system
export const currentLocalPurchaseAtom = atom({
  key: 'currentLocalPurchase',
  default: readCurrentLocalPurchase(),
  effects: [
    ({ onSet }) =>
      onSet((newValue, _, isReset) => {
        if (!isReset) {
          localStorage.setItem(
            CURRENT_LOCAL_PURCHASE_KEY,
            JSON.stringify(newValue),
          )
        }
      }),
  ],
})

const readAuth = () => {
  try {
    const data = localStorage.getItem(CREDENTIALS_KEY)
    return data ? JSON.parse(data) : {}
  } catch {
    return {}
  }
}

export const authAtom = atom({
  key: 'auth',
  default: readAuth(),
  effects: [
    ({ onSet }) =>
      onSet((newValue, _, isReset) => {
        if (!isReset) {
          localStorage.setItem(CREDENTIALS_KEY, JSON.stringify(newValue))
        }
      }),
  ],
})

export const isAuthenticatedSelector = selector({
  key: 'isAuthenticated',
  get: async ({ get }) => {
    const auth = get(authAtom)
    const isLogged = auth.deviceID && (await PTRestAPI.IsDeviceLogged(auth))
    return isLogged
  },
  set: () => {}, // this is here so this become writable
})

export function useAuth() {
  const auth = useRecoilValue(authAtom)
  const { state, contents } = useRecoilValueLoadable(isAuthenticatedSelector)

  return state === 'hasValue' ? (contents ? auth : false) : undefined
}

const profileVersionAtom = atom({
  key: 'profileVersionAtom',
  default: 0,
})

export function useRefreshProfile() {
  const [version, setVersion] = useRecoilState(profileVersionAtom)
  return async () => setVersion(version + 1)
}

let cachedValue
let latestVersion

export const userProfileSelector = selector({
  key: 'userProfile',
  get: async ({ get }) => {
    const currentVersion = get(profileVersionAtom)
    if (latestVersion === currentVersion && cachedValue) return cachedValue

    latestVersion = currentVersion

    if (get(isAuthenticatedSelector)) {
      const auth = get(authAtom)
      const { result } = await PTRestAPI.GetUserProfile(auth, {
        fields: 'name avatar email devices',
      })
      cachedValue = result
      return result
    }
  },
})

//Get the UserPreferences from the server
//Privacy settings, notifications, etc
export const userPreferencesSelector = selector({
  key: 'userPreferences',
  get: async ({ get }) => {
    if (get(isAuthenticatedSelector)) {
      const auth = get(authAtom)
      const result = await PTRestAPI.UserPreferences(
        auth,
        'direct_marketing personalized_marketing',
      )
      return result
    }
  },
})

export const subscriptionSelector = selector({
  key: 'subscription',
  get: async ({ get }) => {
    if (get(isAuthenticatedSelector)) {
      const auth = get(authAtom)
      const language = get(currentLanguageAtom)
      const response = await PTRestAPI.GetActiveSubscription(auth, language, [
        'pid',
        'platform',
        'amount',
      ])
      return response
    }

    return false
  },
  set: () => {}, // this is here so this become writable
})

async function fetchAccount(auth) {
  return await PTRestAPI.GetStoreAccount(auth)
}

// atom to refresh the account
const accountVersionAtom = atom({
  key: 'accountVersionAtom',
  default: 0,
})

export const accountSelector = selector({
  key: 'account',
  get: async ({ get }) => {
    get(accountVersionAtom)
    const auth = get(authAtom) // do not remove this; this makes selector refreshes when auth is changed
    const isAuthenticated = get(isAuthenticatedSelector)
    if (isAuthenticated) return await fetchAccount(auth)
  },
})

export function useRefreshAccount() {
  const [version, setVersion] = useRecoilState(accountVersionAtom)

  return async () => setVersion(version + 1)
}

export function useLogout() {
  const setAuth = useSetRecoilState(authAtom)
  const setIsUserLogged = useSetRecoilState(isAuthenticatedSelector)
  return function () {
    setAuth({})
    setIsUserLogged(false)
  }
}

// the local credentials table contains a valid id that can be used for login: email, facebook_id...
const hasValidLocalCredentialsForLogin = (auth, key) => {
  return auth[key] !== null && auth[key] !== ''
}

export const hasValidRegisteredCredentials = (auth) => {
  return Boolean(
    auth.email || auth.facebook_id || auth.apple_id || auth.google_id,
  )
}

//check for a logged device every X seconds
async function awaitAuth(auth) {
  while (true) {
    await sleep(1000)
    if (await PTRestAPI.IsDeviceLogged(auth)) break
  }
}

export function useLoginWithCredentials() {
  const [state, setState] = useState()
  const [error, setError] = useState(false)
  const setAuth = useSetRecoilState(authAtom)

  const startLogin = async (auth) => {
    setState('pending')
    setError(undefined)

    //make sure we have deviceID
    const deviceID = await ensureDevice(auth)
    const payload = { ...auth, deviceID }
    const response = await PTRestAPI.Login(payload, {
      fetch_user_fields: ['_id', 'name', 'avatar', 'email'],
    })

    // The login was successful
    if (response.user) {
      console.log('Platform Login Success')
      setAuth({ ...payload, _id: response.user._id })
      setState('success')
      return
    }

    if (
      response.error === 'device_not_logged' ||
      response.error === 'unverified_user'
    ) {
      setState('authorization')
      await awaitAuth(payload)
      setAuth(payload)
      await startLogin(payload)
      return
    }

    setError(response.error)
    setState(undefined)
  }

  return { state, error, startLogin: !state && startLogin }
}

// Make sure that a device exists
async function ensureDevice(auth) {
  if (auth?.deviceID && auth.deviceID !== '') {
    return auth.deviceID
  }

  //infinite retry
  while (true) {
    const response = await PTRestAPI.CreateDevice()
    if (response) return response.deviceID

    await sleep(500)
  }
}

export function useCreateInternalUser() {
  const [state, setState] = useState()
  const [error, setError] = useState()
  const setAuth = useSetRecoilState(authAtom)

  const createInternalUser = async function () {
    setState('pending')
    const auth = { deviceID: await ensureDevice() }

    auth.internal_id = await PTRestAPI.RequestInternalID(auth)
    const response = await PTRestAPI.CreateUser(auth)
    auth._id = response._id
    console.log(`Create User returned ${response}`)

    if (response.error) {
      setError(response.error)
      return
    }

    setState('waiting-auth')
    await awaitAuth(auth) //start the device login poll

    setState('success')

    setAuth(auth)
  }

  return { state, error, createInternalUser }
}

// returns {error, state, createUser}
export function useCreateUserWithCredentials() {
  const [state, setState] = useState()
  const [error, setError] = useState()
  const setAuth = useSetRecoilState(authAtom)
  const auth = useAuth()

  const createUser = async function (update) {
    setState(undefined)
    setState('pending')

    const { result } = await PTRestAPI.VerifyCredentials(update)
    if (result) {
      setState(undefined)
      setError('already_registered')
      return
    }

    const payload = { ...auth, deviceID: await ensureDevice(), ...update }
    const response = await PTRestAPI.CreateUser(payload)
    payload._id = response._id ?? auth._id
    console.log(`Create user returned ${response}`)

    if (response.error) {
      setState(undefined)
      setError(response.error)
      return
    }

    setState('waiting-auth')
    await awaitAuth(payload) //start the device login poll

    setState('success')

    //if there is no userID, get it from the server
    if (!payload._id) {
      const { _id } = await PTRestAPI.VerifyCredentials(update)
      payload._id = _id
    }
    //set the local authorization object
    setAuth(payload)
  }

  return { state, error, createUser }
}

export const currencyAtom = atom({
  key: 'currency',
  default: 'bgn',
})
