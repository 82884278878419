import { alpha, createTheme, darken } from '@mui/material'
import '@mui/lab/themeAugmentation'
import { fontWeight, lighten } from '@mui/system'
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes'

// import i18n from 'src/i18n/i18n';

const MainFont = 'Montserrat'
const DiscountFont = 'Sofia Sans Extra Condensed'
const btnMinWidth = '240px'
const smallBtnMinWidth = '160px'

const themeColors = {
  primary: 'rgb(214, 80, 54)',
  primaryAlt: '#232a2e',
  secondary: '#505354',
  success: '#ffa914',
  warning: '#FFA319',
  error: '#FF1943',
  info: '#33C2FF',
  white: '#FFFFFF',
  black: '#202124',
  trueWhite: '#ffffff',
  trueBlack: '#000',
  headerTitleBackground: '#3c3c3c',
}

const colors = {
  gradients: {
    blue1: 'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
    blue2: 'linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%)',
    blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
    blue4: 'linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)',
    blue5: 'linear-gradient(135deg, #97ABFF 10%, #123597 100%)',
    orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
    orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
    orange3: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
    purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
    purple3: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
    pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
    pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
    green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
    green2: 'linear-gradient(to bottom, #00b09b, #96c93d)',
    black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)',
    black2: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
  },
  shadows: {
    success:
      '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
    error:
      '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
    info:
      '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
    primary:
      '0px 1px 4px rgba(112, 99, 192, 0.25), 0px 3px 12px 2px rgba(112, 99, 192, 0.35)',
    warning:
      '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
    card: '0px 0px 2px #757c82',
    cardSm: '0px 0px 2px #757c82',
    cardLg:
      '0 0rem 14rem 0 rgb(255 255 255 / 20%), 0 0.8rem 2.3rem rgb(111 130 156 / 3%), 0 0.2rem 0.7rem rgb(17 29 57 / 15%)',
  },
  layout: {
    general: {
      bodyBg: themeColors.trueWhite,
      borderGray: '#b3b3b3',
      yellow1: '#ffa914',
      yellow2: '#c78410',
      orange: '#f26b1d',
    },
    sidebar: {
      background: themeColors.primaryAlt,
      textColor: themeColors.secondary,
      dividerBg: '#303e47',
      menuItemColor: '#a1a7ab',
      menuItemColorActive: '#ffffff',
      menuItemBg: themeColors.primaryAlt,
      menuItemBgActive: themeColors.primary,
      menuItemIconColor: '#4a5d69',
      menuItemIconColorActive: '#ffffff',
      menuItemHeadingColor: darken(themeColors.secondary, 0.3),
    },
  },
  alpha: {
    white: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white,
    },
    trueWhite: {
      5: alpha(themeColors.trueWhite, 0.02),
      10: alpha(themeColors.trueWhite, 0.1),
      30: alpha(themeColors.trueWhite, 0.3),
      50: alpha(themeColors.trueWhite, 0.5),
      70: alpha(themeColors.trueWhite, 0.7),
      100: themeColors.trueWhite,
    },
    trueBlack: {
      5: alpha(themeColors.trueBlack, 0.02),
      10: alpha(themeColors.trueBlack, 0.1),
      30: alpha(themeColors.trueBlack, 0.3),
      50: alpha(themeColors.trueBlack, 0.5),
      70: alpha(themeColors.trueBlack, 0.7),
      100: themeColors.trueBlack,
    },
    black: {
      5: alpha(themeColors.black, 0.02),
      10: alpha(themeColors.black, 0.1),
      30: alpha(themeColors.black, 0.3),
      50: alpha(themeColors.black, 0.5),
      70: alpha(themeColors.black, 0.7),
      100: themeColors.black,
    },
  },
  secondary: {
    lighter: alpha(themeColors.secondary, 0.85),
    light: alpha(themeColors.secondary, 0.6),
    main: themeColors.secondary,
    dark: darken(themeColors.secondary, 0.2),
  },
  primary: {
    lighter: alpha(themeColors.primary, 0.85),
    light: alpha(themeColors.primary, 0.3),
    main: themeColors.primary,
    dark: darken(themeColors.primary, 0.2),
  },
  success: {
    lighter: alpha(themeColors.success, 0.85),
    light: alpha(themeColors.success, 0.3),
    main: themeColors.success,
    dark: darken(themeColors.success, 0.2),
  },
  warning: {
    lighter: alpha(themeColors.warning, 0.85),
    light: alpha(themeColors.warning, 0.3),
    main: themeColors.warning,
    dark: darken(themeColors.warning, 0.2),
  },
  error: {
    lighter: alpha(themeColors.error, 0.85),
    light: alpha(themeColors.error, 0.3),
    main: themeColors.error,
    dark: darken(themeColors.error, 0.2),
  },
  info: {
    lighter: alpha(themeColors.info, 0.85),
    light: alpha(themeColors.info, 0.3),
    main: themeColors.info,
    dark: darken(themeColors.info, 0.2),
  },
  highlights: [
    '#ffd889',
    '#c0a3ff',
    '#87eaff',
    '#aff1ff',
    '#aff1ff',
    '#333333',
  ],
}

export const GreenFieldsTheme = createTheme({
  // direction: i18n.dir(),
  colors: {
    aldoo: {
      headerTitleBackground: themeColors.headerTitleBackground,
      white: themeColors.white,
      black: themeColors.black,
      trueBlack: themeColors.trueBlack,
      borderGray: colors.layout.general.borderGray,
      yellow1: colors.layout.general.yellow1,
      yellow2: colors.layout.general.yellow2,
      orange: colors.layout.general.orange,
      darkTooltipGray: '#36383d',
      blue: '#3e6a94',
      green: '#1DB954',
      darkItemGradient: 'linear-gradient(60deg, #29323c 0%, #485563 100%)',
      giftCardPages: '#e1c5b7',
    },
    gradients: {
      blue1: colors.gradients.blue1,
      blue2: colors.gradients.blue2,
      blue3: colors.gradients.blue3,
      blue4: colors.gradients.blue4,
      blue5: colors.gradients.blue5,
      orange1: colors.gradients.orange1,
      orange2: colors.gradients.orange2,
      orange3: colors.gradients.orange3,
      purple1: colors.gradients.purple1,
      purple3: colors.gradients.purple3,
      pink1: colors.gradients.pink1,
      pink2: colors.gradients.pink2,
      green1: colors.gradients.green1,
      green2: colors.gradients.green2,
      black1: colors.gradients.black1,
      black2: colors.gradients.black2,
    },
    shadows: {
      success: colors.shadows.success,
      error: colors.shadows.error,
      primary: colors.shadows.primary,
      info: colors.shadows.info,
      warning: colors.shadows.warning,
    },
    alpha: {
      white: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white,
      },
      trueWhite: {
        5: alpha(themeColors.trueWhite, 0.02),
        10: alpha(themeColors.trueWhite, 0.1),
        30: alpha(themeColors.trueWhite, 0.3),
        50: alpha(themeColors.trueWhite, 0.5),
        70: alpha(themeColors.trueWhite, 0.7),
        100: themeColors.trueWhite,
      },
      black: {
        5: alpha(themeColors.black, 0.02),
        10: alpha(themeColors.black, 0.1),
        20: alpha(themeColors.black, 0.2),
        30: alpha(themeColors.black, 0.3),
        50: alpha(themeColors.black, 0.5),
        70: alpha(themeColors.black, 0.7),
        100: themeColors.black,
      },
    },
    secondary: {
      lighter: alpha(themeColors.secondary, 0.1),
      light: alpha(themeColors.secondary, 0.3),
      main: themeColors.secondary,
      dark: darken(themeColors.secondary, 0.2),
    },
    primary: {
      lighter: alpha(themeColors.primary, 0.1),
      light: alpha(themeColors.primary, 0.3),
      main: themeColors.primary,
      dark: darken(themeColors.primary, 0.2),
    },
    success: {
      lighter: alpha(themeColors.success, 0.1),
      light: alpha(themeColors.success, 0.3),
      main: themeColors.success,
      dark: darken(themeColors.success, 0.2),
    },
    warning: {
      lighter: alpha(themeColors.warning, 0.1),
      light: alpha(themeColors.warning, 0.3),
      main: themeColors.warning,
      dark: darken(themeColors.warning, 0.2),
    },
    error: {
      lighter: alpha(themeColors.error, 0.1),
      light: alpha(themeColors.error, 0.3),
      main: themeColors.error,
      dark: darken(themeColors.error, 0.2),
    },
    info: {
      lighter: alpha(themeColors.info, 0.1),
      light: alpha(themeColors.info, 0.3),
      main: themeColors.info,
      dark: darken(themeColors.info, 0.2),
    },
    highlights: colors.highlights,
  },
  general: {
    reactFrameworkColor: '#00D8FF',
    borderRadiusSm: '6px',
    borderRadius: '10px',
    borderRadiusLg: '12px',
    borderRadiusXl: '16px',
  },
  sidebar: {
    background: colors.layout.sidebar.background,
    textColor: colors.layout.sidebar.textColor,
    dividerBg: colors.layout.sidebar.dividerBg,
    menuItemColor: colors.layout.sidebar.menuItemColor,
    menuItemColorActive: colors.layout.sidebar.menuItemColorActive,
    menuItemBg: colors.layout.sidebar.menuItemBg,
    menuItemBgActive: colors.layout.sidebar.menuItemBgActive,
    menuItemIconColor: colors.layout.sidebar.menuItemIconColor,
    menuItemIconColorActive: colors.layout.sidebar.menuItemIconColorActive,
    menuItemHeadingColor: colors.layout.sidebar.menuItemHeadingColor,
    boxShadow: '1px 0 0 #303e47',
    width: '290px',
  },
  header: {
    height: '60px',
    background: themeColors.primaryAlt,
    /*boxShadow: '0px 1px 0px #303e47',*/
    textColor: colors.secondary.main,
  },
  spacing: 9,
  palette: {
    common: {
      black: colors.alpha.black[100],
      white: colors.alpha.white[100],
    },
    mode: 'light',
    primary: {
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark,
    },
    secondary: {
      light: colors.secondary.light,
      main: colors.secondary.main,
      dark: colors.secondary.dark,
    },
    error: {
      light: colors.error.light,
      main: colors.error.main,
      dark: colors.error.dark,
      contrastText: themeColors.trueWhite,
    },
    success: {
      light: colors.success.light,
      main: colors.success.main,
      dark: colors.success.dark,
      contrastText: themeColors.trueWhite,
    },
    info: {
      light: colors.info.light,
      main: colors.info.main,
      dark: colors.info.dark,
      contrastText: themeColors.trueWhite,
    },
    warning: {
      light: colors.warning.light,
      main: colors.warning.main,
      dark: colors.warning.dark,
      contrastText: themeColors.trueWhite,
    },
    text: {
      primary: colors.alpha.black[100],
      secondary: colors.alpha.black[70],
      disabled: colors.alpha.black[50],
    },
    background: {
      paper: colors.alpha.white[100],
      default: colors.layout.general.bodyBg,
    },
    action: {
      active: colors.alpha.black[100],
      hover: colors.primary.lighter,
      hoverOpacity: 0.1,
      selected: colors.alpha.black[10],
      selectedOpacity: 0.1,
      disabled: colors.alpha.black[50],
      disabledBackground: colors.alpha.black[5],
      disabledOpacity: 0.38,
      focus: colors.alpha.black[10],
      focusOpacity: 0.05,
      activatedOpacity: 0.12,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1840,
    },
  },
  components: {
    MuiBackdrop: {
      // styleOverrides: {
      //   root: {
      //     backgroundColor: 'transparent',
      //     backdropFilter: 'none',

      //     '&.MuiBackdrop-invisible': {
      //       backgroundColor: 'transparent',
      //       backdropFilter: 'none',
      //     },
      //   },
      // },
      styleOverrides: {
        root: {
          backgroundColor: alpha(darken('#141c23', 0.5), 0.4),
          backdropFilter: 'blur(2px)',
          zIndex: 10000,

          '&.MuiBackdrop-invisible': {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(2px)',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginLeft: 8,
          marginRight: 8,
          fontWeight: 'bold',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: themeColors.white,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          width: '100%',
          height: '100%',
        },
        body: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          flex: 1,
        },
        '#root': {
          width: '100%',
          height: '100%',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        },
        html: {
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },
        '.child-popover .MuiPaper-root .MuiList-root': {
          flexDirection: 'column',
        },
        '#nprogress': {
          pointerEvents: 'none',
          zIndex: 100000,
        },
        '#nprogress .bar': {
          background: colors.primary.lighter,
        },
        '#nprogress .spinner-icon': {
          borderTopColor: colors.primary.lighter,
          borderLeftColor: colors.primary.lighter,
        },
        '#nprogress .peg': {
          boxShadow: `0 0 15px ${colors.primary.lighter}, 0 0 8px${colors.primary.light}`,
        },
        ':root': {
          '--swiper-theme-color': colors.primary.main,
          colorScheme: 'light',
        },
        code: {
          background: colors.info.lighter,
          color: colors.alpha.black[100],
          borderRadius: 4,
          padding: 4,
        },
        '@keyframes pulse': {
          '0%': {
            transform: 'scale(.75)',
          },
          '20%': {
            transform: 'scale(1.1)',
          },
          '40%': {
            transform: 'scale(.75)',
          },
          '60%': {
            transform: 'scale(1.05)',
          },
          '80%': {
            transform: 'scale(.75)',
          },
          '100%': {
            transform: 'scale(.75)',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.8)',
            opacity: 0,
          },
        },
        '@keyframes float': {
          '0%': {
            transform: 'translate(0%, 0%)',
          },
          '100%': {
            transform: 'translate(3%, 3%)',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: colors.alpha.black[50],
        },
        icon: {
          top: 'calc(50% - 14px)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
            paddingRight: 6,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.alpha.black[50],
          },
          '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primary.main,
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        colorPrimary: {
          fontWeight: 'bold',
          lineHeight: '40px',
          fontSize: 13,
          background: colors.alpha.black[5],
          color: colors.alpha.black[70],
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: -5,
          marginBottom: -5,
        },
        title: {
          fontSize: 15,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          paddingLeft: '6px',
          paddingRight: '6px',
        },
        colorSecondary: {
          background: colors.alpha.black[5],
          color: colors.alpha.black[100],

          '&:hover': {
            background: colors.alpha.black[10],
          },
        },
        deleteIcon: {
          color: colors.alpha.black[50],
          '&:hover': {
            color: colors.alpha.black[70],
          },
        },
        outlinedtoBlack: {
          height: '40px',
          fontSize: 14,
          color: themeColors.black,
          background: 'none',
          borderStyle: 'solid',
          borderRadius: '20px',
          borderColor: colors.alpha.black[70],
          borderWidth: '1px',

          '&.pressed, &:hover': {
            background: themeColors.black,
            color: themeColors.trueWhite,
          },
        },
        reviewChips: {
          fontSize: 14,
          color: colors.alpha.black[50],
          background: 'none',

          '&.pressed, &:hover': {
            background: themeColors.black,
            color: themeColors.trueWhite,
          },

          '& .MuiChip-root.MuiChip-avatar:active': {
            //------------------>to fix! (not working; added for review reactions)
            color: colors.primary.main,
            backgroundColor: themeColors.trueWhite,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',

          '&.Mui-expanded': {
            margin: 0,
          },
          '&::before': {
            display: 'none',
          },
        },
      },
      variants: [
        {
          props: { variant: 'footerAccordion' },
          style: {
            background: 'none',
            width: '90%',
          },
        },
      ],
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 'bold',
        },
        colorDefault: {
          background: colors.alpha.black[30],
          color: colors.alpha.trueWhite[100],
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        avatar: {
          background: colors.alpha.black[10],
          fontSize: 13,
          color: colors.alpha.black[70],
          fontWeight: 'bold',

          '&:first-of-type': {
            border: 0,
            background: 'transparent',
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        alignItemsFlexStart: {
          marginTop: 0,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          fontSize: 13,
          fontWeight: 'bold',
          transition: 'all .2s',
        },
        textPrimary: {
          '&.Mui-selected': {
            boxShadow: colors.shadows.primary,
          },
          '&.MuiButtonBase-root:hover': {
            background: colors.alpha.black[5],
          },
          '&.Mui-selected.MuiButtonBase-root:hover': {
            background: colors.primary.main,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontWeight: '400',
          textTransform: 'none',
          paddingLeft: 16,
          paddingRight: 16,

          '.MuiSvgIcon-root': {
            transition: 'all .2s',
          },
        },
        endIcon: {
          marginRight: -8,
        },
        containedSecondary: {
          backgroundColor: colors.secondary.main,
          color: colors.alpha.white[100],
          border: `1px solid ${colors.alpha.black[30]}`,
        },
        outlinedSecondary: {
          backgroundColor: colors.alpha.white[100],

          '&:hover, &.MuiSelected': {
            backgroundColor: colors.alpha.black[5],
            color: colors.alpha.black[100],
          },
        },
        sizeSmall: {
          padding: '6px 16px',
          lineHeight: 1.5,
        },
        sizeMedium: {
          padding: '8px 20px',
        },
        sizeLarge: {
          padding: '11px 24px',
        },
        textSizeSmall: {
          padding: '7px 12px',
        },
        textSizeMedium: {
          padding: '9px 16px',
        },
        textSizeLarge: {
          padding: '12px 16px',
        },
      },
      variants: [
        {
          props: { variant: 'product-button' },
          style: {
            transition: 'transform 0.4s',
            background: 'none',
            border: 'none',
            p: 0,
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              transform: 'scale(1.07)',
              background: 'none',
            },
          },
        },
        {
          props: { variant: 'menu-button' },
          style: {
            borderRadius: '28px',
            fontSize: '15px',
            height: '58px',
            transition: 'transform 0.4s',
            background: colors.alpha.white[10],
            border: `2px solid ${colors.alpha.white[100]}`,
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              background: colors.alpha.white[10],
              transform: 'scale(1.07)',
            },
          },
        },
        {
          props: { variant: 'book-submenu-button' },
          style: {
            color: colors.secondary.main,
            cursor: 'pointer',
            justifyContent: 'flex-start',
            borderRadius: 0,
            fontSize: 'medium',
            fontWeight: 'normal',
            height: '48px',
          },
        },
        {
          props: { variant: 'book-menu-button' },
          style: {
            height: '60px',
            color: colors.secondary.main,
            cursor: 'pointer',
            justifyContent: 'flex-start',
            fontSize: 'medium',
            fontWeight: 500,
            padding: '6px 16px',
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              color: colors.primary.main,
              background: 'none',
              borderRadius: 0,
              borderBottom: '3px solid',
              fontWeight: 500,
            },
          },
        },
        {
          props: { variant: 'round-button' }, //-----------------------> Added for Avatar btn
          style: {
            cursor: 'pointer',
            borderRadius: '20px',
            padding: 0,
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              transform: 'scale(1.07)',
            },
          },
        },
        {
          props: { variant: 'action-color' },
          style: {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'white',
            borderRadius: '20px',
            fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
            fontSize: '18px',
            fontWeight: 600,
            minWidth: btnMinWidth,
            height: '40px',
            transition: 'transform 0.4s',
            color: 'white',
            background: colors.primary.main,
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              fontSize: '18px',
              background: colors.primary.main,
              transform: 'scale(1.04)',
              transition: 'transform 0.4s',
            },
          },
        },
        {
          props: { variant: 'action-color-short' },
          style: {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'white',
            borderRadius: '20px',
            fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
            fontWeight: 600,
            minWidth: smallBtnMinWidth,
            height: '40px',
            transition: 'transform 0.4s',
            color: 'white',
            background: colors.primary.main,
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              background: colors.primary.main,
              transform: 'scale(1.04)',
              transition: 'transform 0.4s',
            },
          },
        },
        {
          props: { variant: 'action-color', color: '0' }, //-------------------> added for Hero action black btn
          style: {
            width: '320px',
            minWidth: btnMinWidth,
            borderStyle: 'none',
            color: themeColors.white,
            background: themeColors.black,
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              background: themeColors.black,
            },
          },
        },
        {
          props: { variant: 'action-color', color: '1' }, //-------------------> added for "See More Books" btn
          style: {
            minWidth: btnMinWidth,
            color: colors.primary.main,
            borderColor: colors.primary.main,
            background: 'none',
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              color: themeColors.white,
              background: colors.primary.main,
            },
          },
        },
        {
          props: { variant: 'action-color', color: '2' }, //---------------->added for Credit packs and Plans sections (Purchase page)
          style: {
            minWidth: btnMinWidth,
            color: themeColors.black,
            background: 'none',
            borderColor: colors.alpha.black[30],
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              color: colors.primary.dark,
              backgroundColor: colors.alpha.white[50],
            },
          },
        },
        {
          props: { variant: 'action-color', color: '3' }, //-------------------> added for Intro white btns
          style: {
            minWidth: btnMinWidth,
            color: themeColors.black,
            backgroundColor: themeColors.white,
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              background: themeColors.white,
            },
          },
        },
        {
          props: { variant: 'action-color', color: '4' }, //-------------------> added for Hero action yellow btn
          style: {
            width: '320px',
            minWidth: btnMinWidth,
            borderStyle: 'none',
            color: themeColors.black,
            background: colors.layout.general.yellow1,
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              background: colors.layout.general.yellow1,
            },
          },
        },
        {
          props: { variant: 'action-color', color: '5' }, //---------------->black bg + white text (Trailer btn)
          style: {
            minWidth: '110px',
            color: colors.alpha.white[100],
            background: themeColors.trueBlack,
            border: 'none',
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              background: themeColors.trueBlack,
            },
          },
        },
        {
          props: { variant: 'action-color', color: '6' },
          style: {
            //---------------->primary.main bg + white text
            minWidth: btnMinWidth,
            color: colors.alpha.white[100],
            background: colors.primary.dark,
            border: 'none',
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              background: colors.primary.main,
            },
          },
        },
        {
          props: { variant: 'action-no_border' }, //----------------->added for 'See More Books' bttn link
          style: {
            minWidth: btnMinWidth,
            color: colors.primary.main,
            fontSize: '16px',
            backgroundColor: 'white',
            fontWeight: 400,
            transition: 'transform 0.4s',

            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: 'white',
              fontSize: '18px',
              fontWeight: 700,
            },
          },
        },
        {
          props: { variant: 'action-white', color: 'white' }, //----------->added for "add to favs", "play" and social btns
          color: colors.alpha.white[70],
          style: {
            color: colors.alpha.white[70],
            padding: 0,
            minWidth: '16px',

            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              color: alpha(themeColors.trueWhite, 1),
              transform: 'scale(1.15)',
              transition: 'transform 0.7s',
            },
          },
        },
        {
          props: { variant: 'outlined-white' }, //----------------->added for Language Selector

          style: {
            height: '40px',
            color: 'white',
            fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
            fontSize: '16px',
            fontWeight: 600,
            backgroundColor: 'none',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: colors.alpha.trueWhite[30],
            borderRadius: '6px',
            transition: 'transform 0.4s',

            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              color: 'white',
            },
          },
        },
        {
          props: { variant: 'help-info' }, //---------------------------------->added for main color help bttns
          style: {
            color: colors.primary.main,
            width: '16px',
            height: '16px',
            minHeight: 0,
            minWidth: 0,
            p: 0,

            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              fontWeight: 600,
              background: 'none',
            },
          },
        },
        {
          props: { variant: 'help-info-gray' }, //---------------------------------->added for gray help bttns
          style: {
            color: colors.alpha.black[50],
            width: '16px',
            height: '16px',
            minHeight: 0,
            minWidth: 0,
            p: 0,

            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              fontWeight: 600,
              background: 'none',
              transform: 'scale(1.2)',
              transition: 'transform 0.4s',
            },
          },
        },
        {
          props: { variant: 'filter' }, //---------------------------------->added for Filter btn
          style: {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: themeColors.black,
            borderRadius: '15px',
            fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
            fontSize: '16px',
            fontWeight: 600,
            height: '30px',
            color: themeColors.black,
            background: themeColors.white,
            '&.Mui-disabled': {
              borderColor: colors.alpha.black[50],
            },
            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              background: themeColors.primary,
              color: themeColors.white,
              borderColor: themeColors.primary,
              transform: 'scale(1.05)',
              transition: 'transform 0.4s',
            },
          },
        },
        {
          props: { variant: 'big-squared' }, //-------------------> added for Missing Account dialog btns
          style: {
            width: '210px',
            height: '210px',
            background: themeColors.white,
            border: 'solid 1px',
            borderColor: colors.alpha.black[30],
            fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
            fontSize: '16px',
            fontWeight: 600,
            color: themeColors.black,

            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              color: themeColors.white,
              background: themeColors.primary,
              transform: 'scale(1.03)',
              transition: 'transform 0.3s',
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: colors.primary.main,
          background: colors.alpha.white[100],
          transition: 'all .2s',

          '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            color: themeColors.trueWhite,
            background: colors.primary.main,
          },
        },
      },
      variants: [
        {
          props: { variant: 'help_menu' },
          style: {
            width: '150px',
            minWidth: '90px',
            height: '164px',
            fontSize: '14px',
            textTransform: 'none',
            color: colors.alpha.black[50],
            transition: 'transform 0.4s',
            background: 'none',
            border: 'none',

            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              border: 'none',
              borderRadius: '6px',
              color: colors.primary.main,
              background: colors.alpha.white[10],
              transform: 'scale(1.07)',
            },
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: 8,

          '& .MuiTouchRipple-root': {
            borderRadius: 8,
          },
        },
        sizeSmall: {
          padding: 4,
        },
      },
      variants: [
        {
          props: { variant: 'book_scroll_list_btn' },
          style: {
            position: 'absolute',
            top: '30%',
            zIndex: 100,
            width: '3.6rem',
            height: '3.6rem',
            backgroundColor: themeColors.white,
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2)',
            borderRadius: '50%',
            transition: 'transform 0.4s',

            '&:hover': {
              backgroundColor: themeColors.white,
              transform: 'scale(1.05)',
              boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '& .MuiTouchRipple-root': {
            opacity: 0.3,
          },
        },
      },
      variants: [
        {
          props: { variant: 'footerMenu' },
          style: {
            color: colors.alpha.white[50],

            '&:hover, &.MuiListItemButton-selected, &.MuiListItemButton-selected:hover': {
              color: 'white',
              background: 'none',
              transform: 'scale(1.02)',
            },
          },
        },
        {
          props: { variant: 'faq' },
          style: {
            color: colors.primary.main,
            textAlign: 'center',
            px: 0,

            '&:hover, &.MuiListItemButton-selected, &.MuiListItemButton-selected:hover': {
              color: colors.primary.main,
              background: 'none',
              transform: 'scale(1.02)',
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          background: colors.alpha.black[10],
          border: 0,
          height: 1,
        },
        light: {
          background: colors.alpha.white[50],
        },
        vertical: {
          height: 'auto',
          width: 1,

          '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
            height: 'auto',
          },
          '&.MuiDivider-absolute.MuiDivider-fullWidth': {
            height: '100%',
          },
        },
        withChildren: {
          '&:before, &:after': {
            border: 0,
          },
        },
        wrapper: {
          background: colors.alpha.white[100],
          fontWeight: 'bold',
          height: 24,
          lineHeight: '24px',
          marginTop: -12,
          color: 'inherit',
          textTransform: 'uppercase',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          padding: 0,
        },
        elevation0: {
          boxShadow: 'none',
        },
        elevation: {
          boxShadow: colors.shadows.card,
        },
        elevation2: {
          boxShadow: colors.shadows.cardSm,
        },
        elevation24: {
          boxShadow: colors.shadows.cardLg,
        },
        outlined: {
          boxShadow: colors.shadows.card,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
      variants: [
        {
          props: { variant: 'footerMenuLink' },
          style: {
            color: colors.alpha.white[50],
            fontSize: 16,
            fontWeight: 300,
            textDecoration: 'none',

            '&:hover': {
              color: 'white',
              textDecoration: 'none',
            },
          },
        },
      ],
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: 6,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
            transform: 'none',
          },
          '& .MuiSlider-valueLabel': {
            borderRadius: 6,
            background: colors.alpha.black[100],
            color: colors.alpha.white[100],
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,

          '& .MuiListItem-button': {
            transition: 'all .2s',

            '& > .MuiSvgIcon-root': {
              minWidth: 34,
            },

            '& .MuiTouchRipple-root': {
              opacity: 0.2,
            },
          },
          '& .MuiListItem-root.MuiButtonBase-root.Mui-selected': {
            backgroundColor: colors.alpha.black[10],
          },
          '& .MuiMenuItem-root.MuiButtonBase-root:active': {
            backgroundColor: alpha(colors.primary.lighter, 0.2),
          },
          '& .MuiMenuItem-root.MuiButtonBase-root .MuiTouchRipple-root': {
            opacity: 0.2,
          },
        },
        padding: {
          padding: '12px',

          '& .MuiListItem-button': {
            borderRadius: 6,
            margin: '1px 0',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          height: 38,
          minHeight: 38,
          overflow: 'visible',
        },
        indicator: {
          height: 38,
          minHeight: 38,
          borderRadius: 0,
          borderBottom: `3px solid ${colors.alpha.trueBlack[70]}`,
          background: 'none',
        },
        // scrollableX: {
        //   overflow: 'visible !important',
        // },
      },
      variants: [
        {
          props: { variant: 'helpTopicTabs' },
          style: {
            indicator: {
              borderRadius: 0,
              background: 'none',
            },
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          height: 38,
          minHeight: 38,
          borderRadius: 0,
          transition: 'color .2s',
          fontWeight: 500,
          textTransform: 'uppercase',

          '&.MuiButtonBase-root': {
            minWidth: 'auto',
            paddingLeft: 20,
            paddingRight: 20,
            marginRight: 4,
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            color: colors.alpha.trueBlack[100],
            zIndex: 5,
          },
          '&:hover': {
            color: colors.alpha.trueBlack[70],
          },
        },
      },
      variants: [
        {
          props: { variant: 'helpTopic' },
          style: {
            padding: 0,
            minWidth: '100%',
            height: 32,
            minHeight: 32,
            color: themeColors.black,
            transition: 'color .2s',
            fontWeight: 700,
            textTransform: 'none',
            marginBottom: '16px',

            '&:hover, &.Mui-selected, &.Mui-selected:hover': {
              color: colors.primary.main,
            },
          },
        },
      ],
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: 12,
        },
        list: {
          padding: 6,

          '& .MuiMenuItem-root.MuiButtonBase-root': {
            fontSize: 14,
            marginTop: 1,
            marginBottom: 1,
            transition: 'all .2s',
            color: colors.alpha.black[70],

            '& .MuiTouchRipple-root': {
              opacity: 0.2,
            },

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.alpha.black[100],
              background: 'alpha(colors.primary.lighter, 0.2)',
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          background: 'transparent',
          transition: 'all .2s',

          '&:hover, &:active, &.active, &.Mui-selected': {
            color: colors.alpha.black[100],
            background: 'none',
          },
          '&.Mui-selected:hover': {
            background: alpha(colors.primary.lighter, 0.2),
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            color: colors.secondary.main,

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.alpha.black[100],
              background: alpha(colors.primary.lighter, 0.2),
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 1,
        },
        root: {
          '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
            right: 14,
          },
        },
        clearIndicator: {
          background: alpha(colors.error.lighter, 0.2),
          color: colors.error.main,
          marginRight: 8,

          '&:hover': {
            background: alpha(colors.error.lighter, 0.3),
          },
        },
        popupIndicator: {
          color: colors.alpha.black[70],

          '&:hover': {
            background: alpha(colors.primary.lighter, 0.2),
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          '& .MuiIconButton-root': {
            padding: 8,
          },
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '0 !important',
          padding: '0 !important',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          background: colors.alpha.black[5],
        },
        root: {
          transition: 'background-color .2s',

          '&.MuiTableRow-hover:hover': {
            backgroundColor: alpha(colors.alpha.black[5], 0.05),
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: colors.alpha.black[10],
          fontSize: 14,
        },
        head: {
          textTransform: 'uppercase',
          fontSize: 13,
          fontWeight: 'bold',
          color: colors.alpha.black[70],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          lineHeight: 1.5,
          fontSize: 14,
        },
        standardInfo: {
          color: colors.info.main,
        },
        action: {
          color: colors.alpha.black[70],
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          margin: 0,
          zIndex: 5,
          position: 'absolute',
          top: '50%',
          marginTop: -6,
          marginLeft: -6,
        },
        outlined: {
          backgroundColor: colors.alpha.white[100],
          boxShadow: `0 0 0 6px ${colors.alpha.white[100]}`,
        },
        outlinedPrimary: {
          backgroundColor: colors.alpha.white[100],
          boxShadow: `0 0 0 6px ${colors.alpha.white[100]}`,
        },
      },
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          position: 'absolute',
          height: '45%',
          top: '42px',
          borderRadius: 50,
          backgroundColor: colors.alpha.black[10],
        },
      },
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          minHeight: 0,
          padding: '8px 0',

          '&:before': {
            display: 'none',
          },
        },
        missingOppositeContent: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: alpha(colors.alpha.black['100'], 0.95),
          padding: '8px 16px',
          fontSize: 13,
        },
        arrow: {
          color: alpha(colors.alpha.black['100'], 0.95),
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: 33,
          overflow: 'visible',

          '& .MuiButtonBase-root': {
            position: 'absolute',
            padding: 6,
            transition:
              'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
          '& .MuiIconButton-root': {
            borderRadius: 100,
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            opacity: 0.3,
          },
        },
        thumb: {
          border: `1px solid ${colors.alpha.black[30]}`,
          boxShadow: `0px 9px 14px ${colors.alpha.black[10]}, 0px 2px 2px ${colors.alpha.black[10]}`,
        },
        track: {
          backgroundColor: colors.alpha.black[5],
          border: `1px solid ${colors.alpha.black[10]}`,
          boxShadow: `inset 0px 1px 1px ${colors.alpha.black[10]}`,
          opacity: 1,
        },
        colorPrimary: {
          '& .MuiSwitch-thumb': {
            backgroundColor: colors.alpha.white[100],
          },

          '&.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: colors.primary.main,
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          paddingTop: 20,
          paddingBottom: 20,
          background: colors.alpha.black[5],
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.MuiStepIcon-completed': {
            color: colors.success.main,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          h2_dimmed: 'h2',
          subtitle_dimmed: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div',
        },
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: 4,
        },
        paragraph: {
          fontSize: 17,
          lineHeight: 1.7,
        },
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily:
      '"Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 700,
      fontSize: 50,
      fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
      textAlign: 'center',
      color: themeColors.black,
      '& span': { color: themeColors.trueWhite },
    },
    h2: {
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: 20,
      fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
      textAlign: 'center',
      marginBottom: 60,
      whiteSpace: 'pre-line',
      color: themeColors.black,
    },
    h2_dimmed: {
      fontWeight: 700,
      fontSize: 20,
      fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
      textAlign: 'center',
      marginBottom: '1rem',
      lineHeight: 1,
      color: themeColors.white,
    },
    subtitle_dimmed: {
      fontWeight: 500,
      textAlign: 'center',
      fontSize: 18,
      color: themeColors.white,
    },
    h3: {
      fontWeight: 700,
      fontSize: 20,
      fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
      color: colors.alpha.black[100],
    },
    h4: {
      fontWeight: 600,
      fontSize: 20,
      fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
    },
    h5: {
      fontWeight: 700,
      fontSize: 14,
    },
    h6: {
      fontSize: 15,
      fontWeight: 400,
    },
    body1: {
      fontSize: 16,
      fontWeight: 300,
    },
    body2: {
      fontSize: 20,
      fontWeight: 300,
    },
    button: {
      fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
      fontWeight: 600,
    },
    caption: {
      fontSize: 14,
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    caption_invert: {
      //-----------------------------------------added as a temporary style (review later)
      fontSize: 14,
      textTransform: 'uppercase',
      fontWeight: 700,
      color: themeColors.trueWhite,
    },
    subtitle: {
      fontWeight: 500,
      textAlign: 'left',
      fontSize: 18,
      color: themeColors.black,
    },
    subtitle_contrast: {
      fontWeight: 500,
      textAlign: 'left',
      fontSize: 18,
      color: themeColors.trueWhite,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.alpha.black[100],
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 14,
      color: colors.alpha.black[70],
      lineHeight: 1.5,
    },
    subtitle2_dimmed: {
      fontWeight: 400,
      fontSize: 14,
      color: colors.alpha.white[70],
      lineHeight: 1.5,
    },
    subtitle3: {
      fontWeight: 400,
      fontSize: 12,
      color: colors.alpha.black[70],
      lineHeight: 1.5,
    },
    subtitle3_dimmed: {
      fontWeight: 400,
      fontSize: 12,
      color: colors.alpha.white[70],
      lineHeight: 1.5,
    },
    subtitle2_contrast: {
      fontWeight: 400,
      fontSize: 14,
      color: colors.alpha.white[100],
      lineHeight: 1.5,
    },
    overline: {
      fontSize: 13,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    footer_navTitle: {
      fontSize: 18,
      fontWeight: 500,
      fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`, //----------------------->added for footer,
      color: themeColors.white,
      marginBottom: '20px',
    },
    footer_navItem: {
      //----------------------->added for footer
      fontSize: 16,
      fontWeight: 300,
      color: colors.alpha.white[50],
    },
    author_name: {
      //----------------------->added for Product page
      textTransform: 'uppercase',
      fontSize: 16,
      fontWeight: 700,
      color: themeColors.trueWhite,
    },
    menu_section: {
      fontSize: 15,
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    property_name: {
      //------------------------>added for Product page property texts
      fontSize: 16,
      fontWeight: 300,
      color: themeColors.secondary,
    },
    property_value: {
      //----------------------->added for Product page property texts
      fontSize: 16,
      fontWeight: 400,
      color: themeColors.black,
    },
    accent_number: {
      //----------------------->added for big number figures as in Rating section
      fontSize: 72,
      fontWeight: 400,
      lineHeight: 'normal',
      color: themeColors.black,
    },
    discount_number: {
      //----------------------->added for big number figures as in Rating section
      fontFamily: `"${DiscountFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
      fontSize: 72,
      fontWeight: 900,
      lineHeight: 'normal',
      color: themeColors.black,
    },
    bookBoxTitle: {
      fontFamily: `"${MainFont}", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
      fontWeight: 700,
      fontSize: 14,
      color: themeColors.black,
      lineHeight: 1.5,
    },
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
})

GreenFieldsTheme.typography.h1[GreenFieldsTheme.breakpoints.down('md')] = {
  fontSize: 40,
}

GreenFieldsTheme.typography.h1[GreenFieldsTheme.breakpoints.down('sm')] = {
  fontSize: 36,
}

{
  /*GreenFieldsTheme.typography.h2[GreenFieldsTheme.breakpoints.down('sm')] = {
  fontSize: 38,
}*/
}

GreenFieldsTheme.typography.h2_dimmed[
  GreenFieldsTheme.breakpoints.down('sm')
] = {
  fontSize: 38,
}

GreenFieldsTheme.typography.subtitle_dimmed[
  GreenFieldsTheme.breakpoints.down('sm')
] = {
  fontSize: 18,
}

GreenFieldsTheme.typography.footer_navTitle[
  GreenFieldsTheme.breakpoints.down('sm')
] = {
  fontSize: 16,
}
