import { useLocation } from 'react-router'
import { useEffect } from 'react'
import PubSub from 'pubsub-js'

export default function PageViewTracker({ children }) {
  const location = useLocation()

  useEffect(() => {
    PubSub.publish(
      'Tracking_PageView',
      location.search
        ? `${location.pathname}?${location.search}`
        : location.pathname,
    )
  }, [location])

  return children || null
}
