import React, { Component } from 'react';
import {Box} from "@mui/system";
import {log, RenderError} from "./log.jsx";

class ErrorCapture extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  componentDidCatch(error, errorInfo) {
    log({error, stack: errorInfo.componentStack}, 'error')
    this.setState({error, errorInfo})
  }

  render() {
    if (this.state.error) {
      return <Box sx={{display: 'flex', alignItems: 'start', justifyItems: 'center', backgroundColor: '#181818', minHeight: '100vh', pl: 2, pt: 2}}>
        <RenderError error={this.state.error} errorInfo={this.state.errorInfo}/>
      </Box>
    }

    return this.props.children;
  }
}

export default ErrorCapture;
