import React, { useEffect, useState, useRef } from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box'
import { Dialog, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import PTRestAPI from '../../utils/pt_rest_api'
import {Link as RouterLink} from 'react-router-dom'
import {
  accountSelector,
  subscriptionSelector,
  useAuth,
  useLogout,
  userProfileSelector,
} from 'src/utils/platform_auth'
import { getApiUrl } from '../../utils/routing.js'
import { Navigate, useNavigate, useParams } from 'react-router'
import {
  AppleStoreButton,
  GoogleStoreButton,
  PlayNowButton,
} from '../../components/StoreButtons'
import axios from 'axios'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTheme } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  atom,
  useRecoilValueLoadable,
  useSetRecoilState,
  useRecoilState,
} from 'recoil'
import { useTranslation } from '../../utils/localization.js'
import { recoilPersist } from 'recoil-persist'
import Link from "@mui/material/Link";

// @User Dialog

const { persistAtom } = recoilPersist()

export default function NoSubscription() {
  const t = useTranslation()

  return (
    <Stack
        sx={{
            width: {xs: '100%', sm: '45%'},
            height: {xs: '180px',  sm: '280px'},
            justifyContent: 'flex-start',
            alignItems: 'center',
        }}
    >
        <Typography variant="h4" sx={{ mb: {xs: '30px', sm: '80px'} }}>
            {t`Subscription:`}
        </Typography>

        <Typography sx={{ textAlign: {xs: 'center', sm: 'right' } }}>
            {t`No active subscription`}
        </Typography>

        <Stack justifyContent={'flex-end'}
            sx={{
            alignItems: 'center',
            gap: {xs: '8px'},
            pb: {sm: '32px'},
            mt: 'auto'
            }}
        >
            <Button href="/plans" 
              variant="action-color"
              sx={{
                fontSize: '16px'
              }}
            >
            {t`Start Subscription`}
            </Button>
        </Stack>
    </Stack>
  )
}
