/**
 * This file represents that Aldoo Analytics client.
 */
import axios from "axios"
import moment from "moment"

export class AldooAnalytics {
    //If true will print all the logs
    static verbose = false
    //the local time the server time was synced
    static last_sync_local_time
    //the last known synced server time
    static synced_server_time
    static eventBuffer = []
    static ALDOO_ANALYTICS_LOCAL_KEY = "aalk"
    //current analytics session ID as returned by the server
    static sessionID
    static deviceID
    static APP_ID
    //are the analytics enabled from the server
    static enabled
    static flush_rate
    static max_event_buffer
    static API_URL
    //an external function that fetches the user id
    static getUserID
    static isInitialized = false

    /**
     * Log a message to the console respecting the verbose flag
     * @param  {...any} args
     * @returns
     */
    static log(...args) {
        if (!this.verbose) return
        console.log(...args)
    }

    //set the server time as retrieved from the api
    static setServerTime(server_time) {
        this.last_sync_local_time = moment().utc()
        this.synced_server_time = moment(server_time).utc()
    }

    //get the current time converted to server_time in ISO 8601
    static getServerTime() {
        return this.synced_server_time.add(moment().utc().subtract(this.last_sync_local_time))
    }

    /**
     * Submit only valid values
     * @param {*} payload
     * @returns
     */
    static sanitize(payload) {
        const result = {}
        for (let key in payload) {
            const value = payload[key]
            //Empty string values
            if (value && !(typeof (value) == "string" && value.length == 0)) {
                result[key] = value
            }
        }
        return result
    }

    /**
     * Construct the endpoint url for the API
     * @param {*} endPointName
     * @returns
     */
    static ep(endPointName) {
        return `${this.API_URL}/v1/${endPointName}`
    }
    /**
     * Write the local event buffer to the local storage
     */
    static writeEventBufferToDevice() {
        localStorage.setItem(this.ALDOO_ANALYTICS_LOCAL_KEY, JSON.stringify(this.eventBuffer));
    }

    /**
     * Read the events buffer from the local storage
     */
    static readEventBufferFromDevice() {
        let data = localStorage.getItem(this.ALDOO_ANALYTICS_LOCAL_KEY);
        if (data !== null) {
            data = data.replace(/\\u003f/g, "");
        }
        this.eventBuffer = (data !== null && data !== "") ? JSON.parse(data) : [];
    }

    static async flushEvents() {
        //nothing to send anyway
        if (!this.eventBuffer || this.eventBuffer.length === 0) return;

        //payload to the server
        const payload = {
            app_id: this.APP_ID,
            deviceID: this.deviceID,
            events: {}
        }

        //construct the payload, compact the events by session id
        for (let event of this.eventBuffer) {
            let collection = payload.events[event.sessionID];
            if (!collection) collection = [];
            let item = { ...event };
            //remove the session id
            delete item.sessionID;
            collection.push(this.sanitize(item));
            payload.events[event.sessionID] = collection;
        }

        //send the payload
        // Assuming Web.Post is an async function, we need to use await and make the function async
        const response = await axios.post(this.ep("analytics"), this.sanitize(payload))
        //clear the local event Buffer on successful flush
        if (response?.data?.result) {
            this.eventBuffer = [];
            this.writeEventBufferToDevice();
        }
    }

    /**
     * Initialize the Aldoo Analytics client
     * NOTE: getUserID is a function that returns the current user ID
     * Usually this is used like this: () => PlatformAuth.User()._id or fetchAccount
     * @param {*} settings
     * @returns
     */
    static async init({ deviceID, API_URL, app_id, getUserID }) {
        //already initialized
        if (this.isInitialized) return
        //setup the static variables
        this.deviceID = deviceID;
        this.API_URL = API_URL;
        this.APP_ID = app_id;
        this.getUserID = getUserID;

        if (!this.deviceID || this.deviceID === "" || !this.API_URL || this.API_URL === "") {
            this.log('FAILED to initialize the Aldoo Analytics, missing deviceID or API_URL');
            return;
        }

        let response = await axios.post(this.ep('analytics'), {
            session_init: this.sanitize({
                deviceID,
                app_id
            })
        });
        //get the response data
        response = response.data

        if (!response) {
            this.log('Warning: Failed to connect to Aldoo Analytics API');
            return;
        }

        if (response && response.error) {
            this.log('API', this.API_URL);
            this.log('deviceID', this.deviceID);
            this.log('Analytics Init error: ', response.error);
            return;
        }

        this.sessionID = response.session;
        this.flush_rate = response.flush_rate;
        this.max_event_buffer = response.max_event_buffer;
        this.enabled = response.enabled;

        if (this.enabled) {
            this.setServerTime(response.server_time);
            //restore the event buffer that may be pending on the device
            this.readEventBufferFromDevice();
            //start the flush timer
            setInterval(() => this.flushEvents(), this.flush_rate * 1000);
        }

        //mark as initialized
        this.isInitialized = true;
        this.log('Aldoo Analytics initialized');
    }


    /**
     * Track an event + payload. Example of payload:
     * {
     * "key": "value"
     * }
     * and eventID is a string like "SAMPLE_EVENT"
     *
     * @param {*} eventID
     * @param {*} payload
     */
    static track(eventID, payload) {
        //the analytics hasn't been enabled by the server
        if (!this.enabled) return;

        //ignoring null eventID
        if (!eventID) return;

        const userID = this.getUserID ? this.getUserID() : null;

        //append to the local event buffer
        this.eventBuffer.push({
            sessionID: this.sessionID,
            eventID: eventID.toLowerCase(),
            timestamp: this.getServerTime(),
            payload,
            userID
        });

        //make sure the event is recorded on the device
        this.writeEventBufferToDevice();

        //flush to the server in case of the event buffer limit has been reached
        if (this.eventBuffer.length >= this.max_event_buffer) {
            this.flushEvents();
        }
    }
}