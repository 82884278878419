import Typography from '@mui/material/Typography'
import { Button, Stack } from '@mui/material'
import { useAuth } from '../../utils/platform_auth'
import { useTranslation } from '../../utils/localization.js'
import { useTheme } from '@mui/material/styles'
import { Divider } from '@mui/material'
import GiftCard from './giftCard.jsx'
import PTRestAPI from '../../utils/pt_rest_api.jsx'
import { usePromiseWithAuth } from '../../utils/usePromise.js'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { currencyAtom } from '../../utils/platform_auth'
import { currentLanguageAtom } from '../../utils/localization'
import { Link as RouterLink } from 'react-router-dom'

export default function GiftCards() {
  const t = useTranslation()
  const theme = useTheme()
  const auth = useAuth()
  const userID = auth?.user?.id

  const [cards, setCards] = useState([])

  const currency = useRecoilValue(currencyAtom)
  const language = useRecoilValue(currentLanguageAtom)
  const {
    result: cardPrices,
    execute: getCardPrices,
    refresh,
  } = usePromiseWithAuth(
    (auth) =>
      PTRestAPI.GetGiftCardsAndPrices(
        auth,
        {
          enabled: true,
          language,
          fields:
            'amount real_price name pid description discount_label discount_percentage',
        },
        currency,
      ),
    true,
    false,
  )

  const { result: giftCards, execute: getPromoCodes } = usePromiseWithAuth(
    (auth) => PTRestAPI.PreviewPromoCode({ auth, userID }),
    true,
    false,
  )

  //execute the getPromoCodes function
  useEffect(() => {
    if (!getPromoCodes) return

    if (!giftCards) {
      getPromoCodes()
      getCardPrices()
      return
    }
    if (!cardPrices) return

    //for all gift cards, add the price to the card object
    giftCards.forEach((card) => {
      const price = cardPrices.find((price) => price.pid === card.pid)
      card.metadata = price
    })
    //set the gift cards with the price
    setCards(giftCards)
  }, [giftCards, cardPrices])

  return (
    <Stack
      sx={{
        alignItems: { xs: 'center', lg: 'flex-start' },
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: '800px',
        gap: {
          xs: '30px',
          sm: '50px',
        },
        marginX: 'auto',
      }}
    >
      {/* In case there are still no gift cards, show the following message: */}
      {!giftCards?.length && (
        <Stack
          orientation="column"
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            gap: {
              xs: '16px',
              md: '25px',
            },
            py: {
              md: '16px',
            },
            my: {
              xs: '56px',
              md: '25px',
            },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: '20px', sm: '24px', md: '26px' },
              textAlign: 'center',
            }}
          >
            {/* {t`The perfect present for your loved ones is just a click away!`} */}
            {t`no_gift_cards_available_cta`}
          </Typography>
          <Button
            component={RouterLink}
            to="/gift-card-buy"
            variant="action-color"
          >
            {t`Get your gift card`}
          </Button>
        </Stack>
      )}

      {cards && cards.map((giftCard) => <GiftCard card={giftCard} />)}
      {/* <Divider
        sx={{
          width: '100%',
          height: '1px',
          backgroundColor: theme.colors.aldoo.gray,
        }}
      /> */}
    </Stack>
  )
}
