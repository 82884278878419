import axios from 'axios'
import { PLATFORM_API_URL, PLATFORM_API_VERSION } from 'src/config'
import { getApiUrl } from './routing.js'
import sortBy from 'lodash/sortBy.js'

const getRuntimePlatform = () => 'Web'

const IGNORED_FIELDS = ['_id', 'avatar', 'name']

//Send only meaningful parameters to the rest api backend
//@param payload Table Returns a table that has the ignore fields stripped
const sanitize = (payload) => {
  const result = {}
  const keys = Object.keys(payload)
  for (const key of keys) {
    const value = payload[key]
    //Empty string values
    if (
      key &&
      !(typeof value == 'string' && value.length === 0) &&
      !~IGNORED_FIELDS.indexOf(key)
    ) {
      result[key] = value
    }
  }
  return result
}

//constructs an api end point
const ep = (name) => `${PLATFORM_API_URL}/${PLATFORM_API_VERSION}/${name}`

export default class PTRestAPI {
  static async CreateDevice() {
    const payload = {
      create: sanitize({
        platform: getRuntimePlatform(),
        model: navigator.userAgent,
      }),
    }
    const response = await axios.post(ep('user-device'), payload)

    return response.data
  }

  static async CreateUser(auth) {
    const response = await axios.post(ep('user'), {
      create: sanitize(auth),
    })
    return response.data
  }

  static async IsDeviceLogged(auth) {
    const response = await axios.post(ep('user-device'), {
      login_state: sanitize(auth),
    })
    return response.data.login
  }

  static async VerifyCredentials(auth) {
    const response = await axios.post(ep('login'), {
      verify: sanitize(auth),
    })
    return response.data
  }

  static async Login(auth) {
    const response = await axios.post(ep('login'), {
      login: sanitize(auth),
    })
    return response.data
  }

  static async RequestInternalID(auth) {
    const response = await axios.post(ep('user'), {
      internal_id: sanitize(auth),
    })
    return response.data.result
  }

  static async GetStoreProducts(auth, selector) {
    //this field requires auth
    const authCategories = ['user-products', 'user-subscriptions']

    if (
      ~selector.fields?.indexOf('is_bought') ||
      authCategories.indexOf(selector.category)
    )
      selector = { ...selector, ...auth }

    const response = await axios.post(ep('store'), {
      products: sanitize(selector),
    })
    return response.data ? response.data.products : null
  }

  static async GetUserSubscriptions(auth, selector) {
    return await PTRestAPI.GetStoreProducts(auth, {
      ...selector,
      category: 'user-subscriptions',
    })
  }

  static async GetCoinPacks(auth, selector) {
    return await PTRestAPI.GetStoreProducts(auth, {
      ...selector,
      category: 'coins-pack',
    })
  }
  static async GetCoinPacksAndPrices(auth, selector, currency) {
    let coinPacks = await this.GetCoinPacks(auth, selector)
    //filter out gift cards
    coinPacks = coinPacks.filter((p) => !~p.pid.indexOf('gift-card'))
    return await this.getPrices(coinPacks, currency, auth)
  }

  static async GetGiftCards(auth, selector) {
    return await PTRestAPI.GetStoreProducts(auth, {
      ...selector,
      category: 'gift-cards',
    })
  }

  static async GetGiftCardsAndPrices(auth, selector, currency) {
    const products = await this.GetGiftCards(auth, selector)
    return await this.getPrices(products, currency, auth)
  }

  static async getPrices(products, currency, auth) {
    // console.log("WARNING: getPrices is disabled");
    // return []
    const apiUrl = getApiUrl()
    const prices = await axios.post(`${apiUrl}/payment-gateway/prices`, {
      currency,
      credentials: auth,
      ids: products.map((p) => p.real_price),
    })

    for (const product of products) {
      product.price = prices.data.prices[product.real_price]
    }

    sortBy(products, 'price.amount')
    return products
  }

  static async GetOfferedSubscriptions(auth, selector) {
    return await PTRestAPI.GetStoreProducts(auth, {
      ...selector,
      category: 'subscriptions',
    })
  }

  static async GetOfferedSubscriptionsAndPrices(auth, selector, currency) {
    const products = await this.GetOfferedSubscriptions(auth, selector)
    return await this.getPrices(products, currency, auth)
  }

  static async GetActiveSubscription(auth, language, fields) {
    if (fields) {
      const idx = fields.indexOf('subscription')
      if (idx !== -1) fields.splice(idx, 1)
    } else fields = []
    const subs = await PTRestAPI.GetUserSubscriptions(auth, {
      language,
      fields: ['subscription', ...fields],
    })
    return subs?.find((s) => s.subscription.active)
  }

  static async GetMessages(auth, selector) {
    const response = await axios.post(ep('user-message'), {
      get: sanitize({ ...auth, ...selector }),
    })
    return response.data
  }

  static async SendUserMessage(auth, selector) {
    //construct the payload
    const response = await axios.post(ep('user-message'), {
      send: sanitize({ ...auth, ...selector }),
    })
    return response.data
  }

  static async SendUserFeedback(auth, payload) {
    const response = await axios.post(ep('user-message'), {
      feedback: sanitize({ ...auth, ...payload }),
    })
    return response.data
  }

  static async GetUserProfile(auth, selector) {
    const response = await axios.post(ep('user'), {
      profile: sanitize({ ...auth, ...selector }),
    })
    return response.data
  }

  static async UserPreferences(auth, selector) {
    //if selector is a string: "field1 field2" the request is interpreted as GET
    //if selector is an object: { field1: value1, field2: value2 } the request is interpreted as POST
    const response = await axios.post(ep('user'), {
      preferences: sanitize({ ...auth, ...{ data: selector } }),
    })
    return response.data
  }

  static async ChangeUserName(auth, name) {
    name = name.trim()
    const response = await axios.post(ep('user'), {
      profile: sanitize({ ...auth, update: { name } }),
    })

    return response.data
  }

  static async ChangeUserAvatar(auth, avatar) {
    const response = await axios.post(ep('user'), {
      profile: sanitize({ ...auth, update: { avatar } }),
    })

    return response.data
  }

  static async ChangeUserEmail(auth, email) {
    const response = await axios.post(ep('user'), {
      profile: sanitize({ ...auth, update: { email } }),
    })

    return response.data
  }

  static async GetStoreAccount(auth) {
    const response = await axios.post(ep('store'), {
      account: sanitize(auth),
    })
    return response.data
  }

  static async StorePurchase(auth, data) {
    const response = await axios.post(ep('store'), {
      transaction: sanitize({ ...data, ...auth }),
    })
    return response.data
  }
  // Load the contents of a book
  // @param options { pid [required], content = true | false, media = true | false }
  // @return Table book { content: {}, media: {} }
  static async LoadBook(auth, options) {
    //no logged user
    const response = await axios.post(ep('book'), {
      load: sanitize({ ...auth, ...options }),
    })
    return response.data
  }

  static async GetRecommended(auth, count, language, exclude) {
    const response = await axios.post(ep('book'), {
      recommend: sanitize({ ...auth, count, language, exclude }),
    })
    return response.data
  }

  static async GetLocalizationList(auth, pid) {
    const response = await axios.post(ep('localization'), {
      load: sanitize({ ...auth, pid }),
    })
    return response.data
  }

  static async GetSupportedLanguages() {
    const response = await axios.post(ep('localization'), {
      languages: true,
    })
    return response.data
  }

  // page, items_per_page, language, text_search, owned_only
  static async ListBooks(auth, options) {
    const response = await axios.post(ep('book'), {
      list: sanitize({ ...auth, ...options }),
    })
    return response.data
  }

  static async ListCategories(auth, language) {
    const response = await axios.post(ep('book'), {
      categories: sanitize({ ...auth, language }),
    })
    return response.data
  }

  static async GetLocalizationTable(language) {
    const response = await axios.post(ep('localization'), {
      app: {
        language,
        id: 'aldoo-web-app',
      },
    })
    return response.data
  }

  static async SubmitReview(auth, pid, text, rating, moods) {
    const response = await axios.post(ep('review'), {
      save: sanitize({ ...auth, pid, text, rating, tags: moods }),
    })
    return response.status === 204
  }

  static async LoadReviews(auth, pid, my_review, page, items_per_page) {
    return await axios.post(ep('review'), {
      load: sanitize({ ...auth, pid, my_review, page, items_per_page }),
    })
  }

  static async SubmitReaction(auth, target, targetModel, reaction) {
    const response = await axios.post(ep('reaction'), {
      react: sanitize({ ...auth, target, targetModel, reaction }),
    })
    return response.status === 204
  }

  static async SubmitComment(auth, target, targetModel, comment) {
    const response = await axios.post(ep('comment'), {
      add: sanitize({ ...auth, target, targetModel, text: comment }),
    })
    return response.data
  }

  static async LoadComments(auth, target, page, items_per_page) {
    return await axios.post(ep('comment'), {
      load: sanitize({ ...auth, target, page, items_per_page }),
    })
  }

  static async AddFavourite(auth, pid) {
    const response = await axios.post(ep('store'), {
      favorites: sanitize({ ...auth, op: 'add', pid }),
    })
    return response.data?.success
  }

  static async RemoveFavourite(auth, pid) {
    const response = await axios.post(ep('store'), {
      favorites: sanitize({ ...auth, op: 'remove', pid }),
    })
    return response.data?.success
  }

  static async ActivatePromoCode(auth, code) {
    const response = await axios.post(ep('promo-code'), {
      activate: sanitize({ ...auth, code }),
    })
    return response.data
  }

  static async PreviewPromoCode({ auth, codeID, userID }) {
    const response = await axios.post(ep('promo-code'), {
      preview: sanitize({ ...auth, codeID, userID }),
    })
    return response.data
  }

  static async SharePromoCode(auth, codeID, personalization) {
    const { receiver, message, senderName, schedule } = personalization
    const response = await axios.post(ep('promo-code'), {
      share: sanitize({
        ...auth,
        codeID,
        receiver,
        message,
        senderName,
        schedule,
      }),
    })
    return response.data
  }

  //TODO: This would be a server call that encodes the receipt
  //TODO: The server should also invalidate the receipt after it has been used, so it can't be used again
  // Generate a receipt for a product
  //@param pid any
  static async GenerateReceipt(pid) {
    return 'aldoo:' + pid
  }

  static async GetMusicAlbumPreview(auth, book) {
    const response = await axios.post(ep('book'), {
      music_album_preview: sanitize({ ...auth, book }),
    })
    return response.data
  }
}
