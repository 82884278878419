import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import React from "react";

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.aldoo.darkTooltipGray,
    color: theme.colors.aldoo.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))