import { atom, selector, useRecoilValue } from "recoil";
import { recoilPersist } from 'recoil-persist';
import PTRestAPI from "./pt_rest_api";
import { vsprintf } from "sprintf-js";
import _isArray from 'lodash/isArray'
import { authAtom } from "./platform_auth";
import { useMemo } from "react";
import isNumber from "lodash/isNumber.js";

const { persistAtom } = recoilPersist();

export const currentBookLanguageAtom = atom({
    key: 'current-book-language',
    default: 'bg',
    effects: [persistAtom]
})


export const currentLanguageAtom = atom({
    key: 'current-language',
    default: 'en',
    effects: [persistAtom]
})

export const autoDetectedLanguageAtom = atom({
    key: 'auto-detected-language',
    default: false,
    effects: [persistAtom]
})

export const categoriesAtom = selector({
    key: 'categories-list',
    get: async ({ get }) => {
        const auth = get(authAtom)
        const lang = get(currentLanguageAtom)
        return await PTRestAPI.ListCategories(auth, lang)
    }
})


const localizationSelector = selector({
    key: 'localization-table',
    get: async ({ get }) => {
        const lang = get(currentLanguageAtom)
        const { table } = await PTRestAPI.GetLocalizationTable(lang)
        return table
    }
})

export const supportedLanguagesSelector = selector({
    key: 'supported-languages',
    get: async () => {
        const { languages, detectedLanguage } = await PTRestAPI.GetSupportedLanguages()
        return { languages, detectedLanguage }
    }
})

const templatedRegex = /%([a-zA-Z0-9-]+)%/g;
function rawPrintf(str, args) {
    if (!str) return
    let replacements = {};
    let index = -1;
    return str.replace(templatedRegex, (match, group1) => {
        if (!replacements[group1]) {
            replacements[group1] = index;
            index++;
        }
        return args[index];
    });
}

export function useMonthsNames() {
    const t = useTranslation()
    return useMemo(() => {
        return [
            t`Jan`,
            t`Feb`,
            t`Mar`,
            t`Apr`,
            t`May`,
            t`Jun`,
            t`Jul`,
            t`Aug`,
            t`Sep`,
            t`Oct`,
            t`Nov`,
            t`Dec`,
        ]
    }, [])
}

export function useFormatTranslatedDate() {
    const t = useTranslation()
    const monthNames = useMonthsNames()
    return (date) => {
        date = new Date(date)
        return t('%month% %day% %year%, %hour%:%minute%',
            monthNames[date.getMonth()], date.getDate(), date.getFullYear(),
            date.getHours(), date.getMinutes())
    }
}

export function useTranslation() {
    const loc = useRecoilValue(localizationSelector)

    return useMemo(() => (s, ...args) => {
        if (_isArray(s)) {
            if (s.length > 1) {
                throw `Cannot use t with string template that have \${...}`
            }
            return loc[s[0]] ?? s[0]
        }
        const translated = loc[s] ?? rawPrintf(s, args)

        let result = ""
        try {
            result = vsprintf(translated ?? s, args)
        } catch (error) {
            console.error("TODO: Add Sentry to capture this: Failed to translate", s, args)
        }
        return result
    })
}

export function useCurrencyTranslation() {
    const t = useTranslation()
    const currencyTemplates = useMemo(() => ({
        usd: t`$ %amount%`, // @ US Dollar short
        bgn: t`%amount% lv`, // @ Bulgarian Lev short
    }), [t])
    const currencies = useMemo(() => ({
        usd: t`$`, // @ US Dollar short
        bgn: t`lv`, // @ Bulgarian Lev short
    }), [t])

    return {
        currencyTemplates,
        localizedCost: (cost, currency, factor) => {
            factor = factor ?? 1
            const template = currencyTemplates[currency]
            const amountAsString = isNumber(cost)
                ? (cost / factor).toFixed(2)
                : '-'
            if (!template) {
                return `${amountAsString} ${currency}`
            }
            try {
                return vsprintf(template, [amountAsString])
            } catch {
                // console.log("Failed to translate currency(using fallback)", cost, currency, factor);
                return `${amountAsString} ${currency}`
            }

        },
        localizedCurrency: (currency) => {
            return currencies[currency] ?? currency
        },
        convertCoinsToReal: (cost, currency) => {
            const template = currencyTemplates[currency] || currencyTemplates['bgn']
            const exchangeRate = 40
            const real_price = t(template, Math.round(cost / exchangeRate).toFixed(2))
            return real_price
        }
    }
}

export function durationToText(t, duration) {
    return !duration
        ? ''
        : duration === 7
            ? t`week`
            : duration === 30
                ? t`month`
                : t`year`;
}

export function useCreatorRoles() {
    const t = useTranslation()
    return useMemo(() => [
        { id: 'author', label: t`Author` },
        { id: 'illustrator', label: t`Illustrator` },
        { id: 'translator', label: t`Translator` },
        { id: 'narrator', label: t`Narrator` },
    ], [t])
}

export function useBookAuthors(book) {
    //for all authors in the book.authors field, return a csv with the names
    if (!book || !book.author) return ""
    return book.author.map(a => a.name).join(', ')
}