import React from 'react'
import { useRoutes } from 'react-router'
import bookStore from '../router/book-store.jsx'
import { getDomain } from '../utils/routing.js'

export function useRouting() {
  return bookStore;
}

function DomainError() {
  const domain = getDomain()
  return <div>Unsupported domain name {domain}</div>
}

export default function Routing() {
  const routing = useRouting()
  if (routing)
    return useRoutes(routing)

  return <DomainError/>
}
