import { useRef, useState, useCallback, useEffect } from "react";
import {useRecoilValue, useRecoilValueLoadable} from "recoil";
import {authAtom, isAuthenticatedSelector} from "./platform_auth";
import {log} from "../debug/log.jsx";

/**
 * @param {function(*, *): *} getPromise - function that creates the promise
 * @param {boolean} manual - if true the hook will not create a promise until you call execute
 * @param {boolean} suspense - if true the hook will behave following the Suspense protocol and can be wrapped in <Suspense> to show loading indicator
 * returns {execute, error, pending, result, reset}
 *  * execute - function to call to start/restart the promise; the arguments will be passed to getPromise
 *  * reset - resets the result and error; call execute to create a new promise (it is not done automatically)
 *  * result - the result of the promise
 *  * error - exception thrown from the promise
 *  * pending - if the promise has been started but it is not complete
 */
export function usePromise(getPromise, manual, suspense) {
  const [paused, setPaused] = useState(manual);
  const [error, setError] = useState(false);
  const [resolution, setResolution] = useState(false);
  const promise = useRef(false);
  const args = useRef([]);
  const [cachedValue, setCachedValue] = useState(false);

  const changeResult = useCallback((value) => {
    setPaused(false);
    setResolution({result: value})
    setError(false);
  }, [])

  const execute = useCallback((...vargs) => {
    args.current = vargs;
    promise.current = false;
    setPaused(false);
    setResolution(false);
    setError(false);
  }, [])

  const refresh = useCallback((...vargs) => {
    args.current = vargs;
    promise.current = false;
    setPaused(false);
    setCachedValue(resolution)
    setResolution(false);
    setError(false);
  }, [resolution])

  const reset = useCallback(() => {
    promise.current = false;
    setPaused(true);
    setResolution(false);
    setError(false);
  }, [])

  //console.log({error, execute, reset, changeResult, result: resolution?.result})
  if (paused)
    return { execute, reset, refresh, changeResult }

  if (error) {
    if (suspense)
      throw error
    else
      return {error, execute, reset, refresh, changeResult}
  }

  if (resolution)
    return { result: resolution.result, execute, reset, refresh, changeResult }

  if (promise.current) {
    if (suspense)
      throw promise.current
    else
      return { pending: true, result: cachedValue?.result }
  }

  promise.current = getPromise(...args.current)
    .then(
      result => {
        setCachedValue(false)
        setResolution({result})
      },
      error => {
        log(error, "error")
        setError(error)
      }
    )

  return suspense ? {} : { pending: true, result: cachedValue?.result }
}

export function usePromiseWithAuth(getPromise, manual, suspense) {
  const {contents} = useRecoilValueLoadable(isAuthenticatedSelector)
  const auth = useRecoilValue(authAtom)

  return usePromise(async (...args) => {
    await contents
    return await getPromise(auth, ...args)
  }, manual, suspense)
}