import styled from '@mui/system/styled'
import Link from '@mui/material/Link'
import React from 'react'
import StaticImg from '../StaticImg/index.jsx'

const LogoWrapper = styled(Link)(
  ({}) => `
        display: flex;
        width: fit-content;
        height: 33px;
        justify-content: center;
`,
)

export default function Logo({ sx, small, scale, homeAddress }) {
  return (
    <LogoWrapper href={homeAddress ?? '/'}>
      {small ? (
        <StaticImg
          src={'aldoo-small.png'}
          width={'37px'}
          height={'auto'}
          alt="Aldoo logo"
          sx={{ ...sx }}
        />
      ) : (
        <StaticImg
          src={'aldoo.png'}
          width={'162px'}
          height={'33px'}
          crop={'162x33'}
          sx={{ ...sx }}
          alt="Aldoo logo"
        />
      )}
    </LogoWrapper>
  )
}
