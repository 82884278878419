import { useEffect, useState } from "react";

export function addClass(el, classId) {
    if (!el.classList.contains(classId))
        el.classList.add(classId)
}

export function removeClass(el, classId) {
    if (el.classList.contains(classId))
        el.classList.remove(classId)
}

export function setClass(el, classId, isOn) {
    if (isOn !== el.classList.contains(classId)) {
        if (isOn)
            el.classList.add(classId)
        else
            el.classList.remove(classId)
    }
}
export function scrollToTop() {
    const contentContainer = document.querySelector('#content-container')
    //Container may be null, for example the BookPlayer iFrame
    if (contentContainer) {
        contentContainer.scrollTo(0, 0)
    }

    window.scrollTo(0, 0)
}


const isPointInRect = (x, y, rect) => {
    return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
};

export function useMouseIdle(time, panelRef, onMouseIdleChange) {
    const [isMouseIdle, setIsMouseIdle] = useState(false);

    useEffect(() => {
        let timer;
        const handleMouseMove = (e) => {
            const rect = panelRef.current?.getBoundingClientRect();
            setIsMouseIdle(false);
            clearTimeout(timer);
            timer = setTimeout(() => {
                const isMouseOutside = rect && !isPointInRect(e.clientX, e.clientY, rect);
                setIsMouseIdle(isMouseOutside)

            }, time * 1000);
        };

        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            clearTimeout(timer);
        };
    }, [time]);


    useEffect(() => {
        onMouseIdleChange(isMouseIdle)
        //restore the cursor visibility when the component is unmounted
        return () => {
            onMouseIdleChange(false)
        }
    }, [isMouseIdle, onMouseIdleChange])



    return isMouseIdle;
};