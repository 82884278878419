import React, { lazy, Suspense } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import User from "../content/User/index.jsx";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader/>}>
      <Component {...props} />
    </Suspense>
  );

// Management

const TermsOfUse = Loader(lazy(() => import('src/content/TermsOfUse')));
const PrivacyPolicy = Loader(lazy(() => import('src/content/PrivacyPolicy')));
const Login = Loader(lazy(() => import('src/content/Login')));
const SignUp = Loader(lazy(() => import('src/content/SignUp')));

const servicePagesRoutes = [
    {
        path: "/login",
        element: <Login/>
    },
    {
        path: "/user",
        element: <User/>
    },
    {
        path: "/user/:section",
        element: <User/>
    },
    {
        path: "/sign-up",
        element: <SignUp/>
    },
    {
        path: "/connect",
        element: <SignUp connect={true}/>
    },
    {
        path: "/terms-of-use",
        element: <TermsOfUse/>
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy/>
    },
];

export default servicePagesRoutes;
