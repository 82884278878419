import React, { useEffect } from "react";
import NProgress from "nprogress";
import { Box, CircularProgress } from "@mui/material";
import { useStartStopWithDelay } from "../../utils/time.js";

let waiting = 0;
function SuspenseLoader({ noCircularProgress }) {
  const { start, stop } = useStartStopWithDelay(0, 200, (started) => {
    if (started) NProgress.start();
    else NProgress.done();
  });
  useEffect(() => {
    if (++waiting === 1) start();

    return () => {
      if (--waiting === 0) stop();
    };
  }, []);

  return noCircularProgress ? null : (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={64} disableShrink thickness={3} />
    </Box>
  );
}

export default SuspenseLoader;
