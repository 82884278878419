import { parseWidthHeight } from '../StaticImg/utils.js'

const firstImageUrl = /^\S*/
import isString from 'lodash/isString'
import './image.css'
import { styled } from '@mui/system'
import isNumber from 'lodash/isNumber.js'
import { useEffect, useLayoutEffect, useRef } from 'react'

const PictureStyled = styled('picture')({})
const ImgStyled = styled('img')({})
const imgTypes = ['image/webp', 'image/jpeg']

export default ({
  src,
  crop,
  sx,
  fit,
  fitPosition,
  alt,
  width,
  height,
  borderRadius,
  onReady,
}) => {
  const imgRef = useRef()
  if (onReady) {
    useLayoutEffect(() => {
      if (imgRef.current.complete) {
        onReady()
      } else {
        imgRef.current.addEventListener('load', onReady)
        return () => {
          imgRef.current.removeEventListener('load', onReady)
        }
      }
    })
  }
  if ((!width || !height) && crop) {
    const size = !width && !height && parseWidthHeight(crop)
    width ??= size.width
    height ??= size.height
  }

  width = isNumber(width) ? `${width}px` : width
  height = isNumber(height) ? `${height}px` : height
  fit ??= ''
  if (isString(src) || !src) {
    return (
      <PictureStyled sx={{ width, height, ...sx }}>
        <ImgStyled
          src={src}
          alt={alt}
          sx={{
            width,
            height,
            ...sx,
          }}
          OK
          ref={imgRef}
        />
      </PictureStyled>
    )
  } else {
    const responsiveData = src[crop ?? 'default']
    if (!responsiveData)
      throw new Error(
        `Responsive data for crop '${crop}' not found in ${JSON.stringify(
          src,
        )}`,
      )
    const fallbackJpg = firstImageUrl.exec(
      responsiveData[responsiveData.length - 1],
    )
    return (
      <PictureStyled
        sx={{ width, height, ...sx }}
        className={`img-component ${fit}`}
      >
        {responsiveData.map((src, i) => (
          <source key={i} srcSet={src} type={imgTypes[i]} />
        ))}
        <ImgStyled
          ref={imgRef}
          sx={{
            objectPosition: fitPosition,
            borderRadius,
          }}
          src={fallbackJpg[0]}
          alt={alt}
        />
      </PictureStyled>
    )
  }
}

export function getImageSize(img) {
  if (!img) return { width: 0, height: 0 }
  //TODO: this is a workaround to avoid a crash while developing the book player
  if (typeof img == 'string') return { width: 128, height: 128 }

  const sizeRe = /--(\d*)x(\d*)\./m
  const parsed = sizeRe.exec(img.default)

  return { width: Number(parsed[1]), height: Number(parsed[2]) }
}
