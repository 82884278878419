import Typography from '@mui/material/Typography'
import { useTranslation } from '../../utils/localization.js'
import React, { useRef, useState, forwardRef, useEffect } from 'react'
import Button from '@mui/material/Button'
import { Box, Stack } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import Img from '../../components/Img/index.jsx'
import SearchIcon from '@mui/icons-material/Search'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Divider } from '@mui/material'
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined'
import { InputBase } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import { getPageSubtitles } from './utils.js'
import { LightTooltip } from '../LightTooltip/index.jsx'
import AnimatedPlaybackIcon from '../AnimatedPlaybackIcon/index.jsx'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { useMouseIdle } from '../../utils/dom.js'
import isMobile from 'ismobilejs'

//get the list of items to display based on chapters setting
const getListItems = (pages, chaptersOnly) => {
  const result = pages.filter((page) => {
    if (chaptersOnly) return page.chapterIndex && page.chapterIndex > 0
    //in non chapter mode, pass everything
    return true
  })

  //calculate the page nagivation index
  result.forEach((page, index) => {
    page.navigateIndex = pages.indexOf(page)
  })

  return result
}

const getChapterIndex = (page, pages) => {
  let chapter = 0
  for (let idx = 0; idx < pages.length; idx++) {
    const item = pages[idx]
    if (item.chapterIndex && item.chapterIndex > 0) {
      chapter = item.chapterIndex
    }

    if (idx == pages.indexOf(page)) {
      break
    }
  }

  return chapter
}

const getChapterDuration = function (chapterIndex, pages, layout) {
  let totalDuration = 0
  let found = false

  for (const item of pages) {
    if (found && item.chapterIndex > 0 && item.chapterIndex !== chapterIndex) {
      break
    }

    if (!found) found = item.chapterIndex === chapterIndex

    if (found) {
      const duration = item.subtitle
        ? item.subtitle[layout].audioDuration
        : item.duration
      totalDuration += duration
    } else if (totalDuration > 0) {
      break
    }
  }

  return totalDuration
}

export default forwardRef(function AudioPlaylist(
  {
    isFullscreen,
    //the content here is the book/content object
    content,
    layout,
    playbackController,
    onClose,
  },
  ref,
) {
  const t = useTranslation()
  const inputRef = useRef()
  const theme = useTheme()
  const [textSearch, setTextSearch] = useState('')
  const [showSearch, setShowSearch] = useState(false)
  const [isMouseOverIdx, setMouseOverIdx] = useState(-1)
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0)

  //if true, will only show pages marked as chapters
  const chaptersOnly = content.bookSettings.useChapters

  //get the list of items to display based on chapters setting
  const listItems = getListItems(content.pages, chaptersOnly)

  const playlistMouseIdle = useMouseIdle(1.5, ref, (isIdle) => {})

  useEffect(() => {
    if (chaptersOnly && playbackController && playbackController.pageNum) {
      setCurrentChapterIndex(
        getChapterIndex(
          content.pages[playbackController.pageNum - 1],
          content.pages,
        ),
      )
    }
  }, [playbackController.pageNum])

  return (
    <Stack
      ref={ref}
      sx={{
        display: isFullscreen && playlistMouseIdle ? 'none' : 'flex',
        position: {
          xs: 'fixed',
          md: 'absolute',
        },
        left: 0,
        width: {
          xs: '100%',
          md: '350px',
          lg: '420px',
        },
        height: '100%',
        alignItems: 'flex-start',
        justifyContent: 'start',
        backgroundColor: theme.colors.aldoo.black,
        zIndex: 1000,
        pt: '16px',
      }}
    >
      {/*---------------------------> Thumb + Heading + Author + Search */}
      <Stack
        sx={{
          flexDirection: 'row',
          width: '100%',
          gap: '16px',
          ml: '20px',
        }}
      >
        <Img
          width={'70px'}
          height={'70px'}
          src={content.square}
          fit="cover"
          sx={{
            flexShrink: 0,
            backgroundSize: 'cover',
            width: '70px',
            height: '70px',
            borderRadius: '1px',
            border: 'solid 1px #ffffff55',
          }}
        />
        {/*---------------------> Album + Author names -- need wiring */}
        <Stack
          sx={{
            alignItems: 'flex-start',
            height: '60px',
          }}
        >
          <Typography variant="h4" sx={{ color: theme.colors.aldoo.white }}>
            {content.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignContent: 'flex-start',
              width: '100%',
            }}
          >
            {content.author_display_name ? (
              <Typography
                variant="h6"
                sx={{ color: theme.colors.aldoo.yellow1 }}
              >
                {content.author_display_name}
              </Typography>
            ) : (
              content.author.map((author, idx) => (
                <Typography
                  variant="h6"
                  sx={{
                    ml: idx == 0 ? 0 : 1,
                    color: theme.colors.aldoo.yellow1,
                  }}
                >
                  {author.name}
                </Typography>
              ))
            )}
          </Box>
        </Stack>

        <Button
          variant="action-white"
          color="white"
          onClick={() => {
            const isVisible = !showSearch
            setTextSearch('')
            if (isVisible) {
              setTimeout(() => {
                inputRef.current.focus()
              }, 100)
            }
            setShowSearch(isVisible)
          }}
          sx={{ ml: 'auto', mr: '40px' }}
        >
          <SearchIcon fontSize="large" />
        </Button>
      </Stack>
      {/*--------------------------------------------------> Add Search Box */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
        }}
      >
        <InputBase
          autoFocus
          inputRef={inputRef}
          height={40}
          fullWidth
          endAdornment={
            <IconButton
              sx={{ visibility: textSearch ? 'visible' : 'hidden' }}
              onClick={() => setTextSearch('')}
            >
              <ClearOutlinedIcon sx={{ color: theme.colors.alpha.white[50] }} />
            </IconButton>
          }
          sx={{
            mt: '20px',
            mx: '20px',
            maxHeight: '40px',
            display: showSearch ? 'flex' : 'none',
            flex: 1,
            color: theme.colors.aldoo.white,
            backgroundColor: theme.colors.aldoo.trueBlack,
            borderRadius: '10px',
            pl: '16px',
          }}
          placeholder={t`Find a song...`}
          value={textSearch}
          onChange={(e) => {
            setTextSearch(e.target.value)
          }}
        />
      </Box>
      {/*--------------------------------------------------> Track List */}
      <Box
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          maxHeight: '100%',
          overflow: 'auto',
          width: '100%',
          height: 'auto',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
            mt: 1,
          }}
        >
          <List
            sx={{
              color: 'gray',
              overflowX: 'hidden',
              overflowY: 'auto',
              height: '100%',
              '& li.MuiListItem-root::marker': {
                color: '#9e9e9e',
                fontWeight: 200,
                fontSize: '14px',
              },
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                backgroundColor: theme.colors.alpha.white[30],
              },
            }}
          >
            {listItems.filter(
              (item) =>
                item.title &&
                item.title.toLowerCase().indexOf(textSearch.toLowerCase()) !==
                  -1,
            ).length == 0 &&
              showSearch && (
                <ListItem>
                  <ListItemText
                    sx={{ textAlign: 'center' }}
                  >{t`No results`}</ListItemText>
                </ListItem>
              )}
            {listItems.map((page, index) => {
              let { audioDuration } = getPageSubtitles(page.subtitle, layout)

              if (chaptersOnly) {
                audioDuration = getChapterDuration(
                  page.chapterIndex,
                  content.pages,
                  layout,
                )
              }

              const sec = String(Math.round(audioDuration % 60)).padStart(
                2,
                '0',
              )
              const min = String(Math.floor(audioDuration / 60))

              //apply text search filter
              if (
                page.title &&
                page.title.toLowerCase().indexOf(textSearch.toLowerCase()) ===
                  -1
              )
                return null
              const isItemSelected = chaptersOnly
                ? currentChapterIndex === getChapterIndex(page, content.pages)
                : playbackController.pageNum === index + 1

              let isMouseOver = isMouseOverIdx === index
              return (
                <ListItem
                  key={index}
                  onMouseEnter={() =>
                    isMouseOverIdx !== index && setMouseOverIdx(index)
                  }
                  onMouseLeave={() => setMouseOverIdx(-1)}
                  onClick={(e) => {
                    playbackController.setPaused(false)
                    playbackController.requestSeek(0, page.navigateIndex + 1)
                    setCurrentChapterIndex(getChapterIndex(page, content.pages))
                    setShowSearch(false)
                    setTextSearch('')
                    setTimeout(() => {
                      e.target.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      })
                    }, 100)
                  }}
                  sx={
                    //when playing, highlight current track
                    {
                      color: isItemSelected
                        ? theme.colors.primary.main
                        : 'white',
                      display: 'list-item',
                      cursor: 'pointer',
                      fontSize: '14px',
                      background: isItemSelected
                        ? theme.colors.primary.lighter
                        : 'inherit',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0px 14px 20px rgba(0, 0, 0, 0.3)',
                        background: theme.colors.alpha.white[10],
                        transition: 'transform 0.4s',
                      },
                    }
                  }
                >
                  <Stack
                    direction={'row'}
                    sx={{
                      height: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        height: 'auto',
                        width: '20px',
                        textAlign: 'right',
                        mr: '15px',
                        pt: '10px',
                      }}
                    >
                      <ListItemText sx={{}}>
                        {isItemSelected && !playbackController.paused ? (
                          <AnimatedPlaybackIcon />
                        ) : isMouseOver ? (
                          <PlayArrowIcon />
                        ) : (
                          <Typography
                            sx={{ color: 'inherit' }}
                            variant="subtitle_contrast"
                          >
                            {`${index + 1}`}
                          </Typography>
                        )}
                      </ListItemText>
                    </Box>
                    <Box>
                      <ListItemText>
                        <Typography
                          sx={{ color: 'inherit', ml: '10px' }}
                          variant="subtitle_contrast"
                        >
                          {page.title}
                        </Typography>
                      </ListItemText>
                      <ListItemText>
                        <Typography
                          variant="subtitle2_dimmed"
                          sx={{
                            fontWeight: 200,
                            color: 'inherit',
                            ml: '10px',
                          }}
                        >
                          {`${min}:${sec}`}
                        </Typography>
                      </ListItemText>
                    </Box>
                  </Stack>
                </ListItem>
              )
            })}
            <ListItem sx={{ height: 100 }}></ListItem>
          </List>
        </Box>
      </Box>
      {(isFullscreen || isMobile(window.navigator).any) && (
        <Stack
          sx={{
            display: {
              xs: 'flex',
              md: 'flex',
            },
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.colors.aldoo.black,
            px: '16px',
          }}
        >
          <Divider
            flexItem
            sx={{ bgcolor: theme.colors.alpha.white[10], mt: 'auto' }}
          />

          <LightTooltip title={t`Hide Playlist`} placement="top">
            <Button
              variant="action-white"
              color="white"
              sx={{ py: '20px' }}
              onClick={() => {
                if (onClose) onClose()
              }}
            >
              <KeyboardDoubleArrowDownOutlinedIcon fontSize="large" />
            </Button>
          </LightTooltip>
        </Stack>
      )}

      {/*------------------------------------------> Divider + Scroll button */}
    </Stack>
  )
})
