import axios from "axios";
import {log, updateLog} from "./log.jsx";

export default function () {
  const originalPost = axios.post

  axios.post = async function (url, data, config) {
    const handle = log({url, data, status: 'pending'}, 'warning')
    try {
      const res = await originalPost(url, data, config)
      if (res.data)
        updateLog(handle, {url, data, status: res.status, result: res.data}, res.data.error && 'error')
      else
        updateLog(handle, {url, data, status: res.status}, res.status > 299 && 'error')
      return res
    }
    catch (e) {
      updateLog(handle, {url, data, status: e.status, result: e}, 'error')
      throw e
    }
  }
}
