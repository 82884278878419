import React from 'react';
import {ThemeProvider} from '@mui/material';
import {StyledEngineProvider} from '@mui/material/styles';
import {GreenFieldsTheme} from './GreenFieldsTheme';

const ThemeProviderWrapper = function (props) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={GreenFieldsTheme}>{props?.children}</ThemeProvider>
        </StyledEngineProvider>
    );
};

export default ThemeProviderWrapper;
