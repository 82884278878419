import { useEffect } from 'react'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import {
  autoDetectedLanguageAtom,
  currentLanguageAtom,
  supportedLanguagesSelector,
} from '../../utils/localization'
export function LanguageAutoDetect() {
  const [language, setLanguage] = useRecoilState(currentLanguageAtom)
  const { state, contents } = useRecoilValueLoadable(supportedLanguagesSelector)
  const [autoDetectedLanguage, setAutoDetectedLanguage] = useRecoilState(
    autoDetectedLanguageAtom,
  )

  useEffect(() => {
    //if the use hasn't changed the language yet, use the autodetect
    if (!autoDetectedLanguage && state === 'hasValue') {
      let { detectedLanguage } = contents
      //check if the detected language is supported
      const supportedLanguages = contents.languages.map(({ code }) => code)
      //revert to english if the detected language is not supported
      if (!supportedLanguages.includes(detectedLanguage)) {
        detectedLanguage = 'en'
      }
      setLanguage(detectedLanguage)
      setAutoDetectedLanguage(detectedLanguage)
    }
  }, [state])
}
