import { getSuffix, parseWidthHeight } from './utils.js'
import './image.css'
import { styled } from '@mui/system'
import sizes from './sizes.json'
import urljoin from 'url-join'
import isString from 'lodash/isString.js'
import { useState } from 'react'
import isNumber from 'lodash/isNumber.js'
import CircularProgress from '@mui/material/CircularProgress'

const PictureStyled = styled('picture')({})
const ImgStyled = styled('img')({})

const extension = /\..*$/

export default function StaticImg({
  basePath,
  src,
  crop,
  fit,
  fitPosition,
  borderRadius,
  lossless,
  variations,
  alt,
  sx,
  width: componentWidth,
  height: componentHeight,
}) {
  const [retry, setRetry] = useState(0)
  if (retry > 0) {
    return <CircularProgress size={24} />
  }

  function requestRebuild() {
    if (import.meta.hot) {
      import.meta.hot.send('img:rebuild')
      setRetry(retry + 1)
      setTimeout(() => setRetry(retry - 1), 200)
    }
  }

  const key = basePath ? urljoin(basePath, src) : src

  if (!sizes[key]) {
    requestRebuild()
    return <CircularProgress size={24} />
  }

  const size = parseWidthHeight(crop, sizes[key]) ?? sizes[key]

  if (!size) {
    requestRebuild()
    return <CircularProgress size={24} />
  }
  const { width, height } = size

  src = urljoin('/static/p-images', basePath ?? '', src.replace(extension, ''))
  let fallbackJpg

  let webp = ''
  let jpg = ''
  const suffix = getSuffix(fit, lossless)

  for (let variation of variations ?? [1]) {
    if (isString(variation)) {
      if (variation.endsWith('h')) {
        variation = variation.replace('h', '')
        variation = variation / height
      }
      const w = Math.round(variation * width)
      const h = Math.round(variation * height)
      webp += `${src}--${w}x${h}${suffix}.webp ${w}w ${h}h, `
      const jpgName = `${src}--${w}x${h}${suffix}.jpg`
      jpg += `${jpgName} ${w}w ${h}h, `
      if (!fallbackJpg) fallbackJpg = jpgName
    } else {
      if (variation > 1) variation = variation / width
      const w = Math.round(variation * width)
      const h = Math.round(variation * height)
      webp += `${src}--${w}x${h}${suffix}.webp ${w}w  ${h}h, `
      const jpgName = `${src}--${w}x${h}${suffix}.jpg`
      jpg += `${jpgName} ${w}w ${h}h, `
      if (!fallbackJpg) fallbackJpg = jpgName
    }
  }

  if (crop) {
    componentWidth = componentWidth ?? width
    componentHeight = componentHeight ?? height
    if (isNumber(componentWidth)) componentWidth = `${componentWidth}px`
    if (isNumber(componentHeight)) componentHeight = `${componentHeight}px`
  }
  return (
    <PictureStyled
      key={`image-${retry}`}
      className={`img-component ${fit ?? 'cover'}`}
      sx={{
        width: componentWidth,
        height: componentHeight,
        ...sx,
      }}
    >
      <source srcSet={webp} type="image/webp" />
      <source srcSet={jpg} type="image/jpeg" />
      <ImgStyled
        sx={{ objectPosition: fitPosition, borderRadius }}
        src={fallbackJpg}
        alt={alt}
        onError={requestRebuild}
      />
    </PictureStyled>
  )
}
