import { useEffect } from 'react'
import { AldooAnalytics as Analytics } from '../../utils/aldoo-analytics'
import { useAuth } from '../../utils/platform_auth'
import { ANALYTICS_URL } from '../../config'

Analytics.verbose = false

export function AldooAnalytics() {
  const auth = useAuth()

  useEffect(() => {
    if (!auth) return
    const getUserID = () => auth?._id
    const { deviceID } = auth
    const app_id = 'aldoo-books'
    const API_URL = ANALYTICS_URL
    Analytics.init({ deviceID, API_URL, app_id, getUserID })
  }, [auth])

  return null
}
