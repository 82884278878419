import React, { lazy, Suspense } from 'react'
import './App.css'
import CssBaseline from '@mui/material/CssBaseline'
import ThemeProvider from './theme/ThemeProvider'
import Routing from './content/Routing'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { Helmet } from 'react-helmet'
import PageViewTracker from './components/Tracking/PageViewTracker'
import FacebookTracking from './components/Tracking/facebook'
// import GTMTracking from './components/Tracking/gtm'
import GoogleTracking from './components/Tracking/google'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { gtmParams } from 'src/config'
import SuspenseLoader from './components/SuspenseLoader'
import ErrorCapture from './debug/error-capture.jsx'
import initRequestLogging from './debug/request-log.js'
import { ScrollToTop } from './components/ScrolToTop/index.jsx'
import 'nprogress/nprogress.css'
import { AldooAnalytics } from './components/AldooAnalytics'
import { generateSitePageMeta } from './utils/page-meta-tags'
import DocumentMeta from 'react-document-meta'
import { LanguageAutoDetect } from './components/LanguageAutoDetect'

const ConnectAccount = lazy(() =>
  import('./components/ConnectAccount/index.jsx'),
)
const LogRender = lazy(() => import('./debug/log.jsx'))

//Route the react warning to console.warn, not errors
//--------------------------------------------------
const consoleWarn = console.warn
const consoleError = console.error
const SUPPRESSED_WARNINGS = ['Warning: ']
console.error = function filterWarnings(msg, ...args) {
  if (
    !SUPPRESSED_WARNINGS.some(
      (entry) => msg && msg.toString().indexOf(entry) != -1,
    )
  ) {
    consoleError(msg, ...args)
  } else {
    consoleWarn(msg, ...args)
  }
}
//--------------------------------------------------

const debugSupport = window.location.hostname.startsWith('localhost')
if (debugSupport) {
  const head = document.getElementsByTagName('head')[0]
  const s = document.createElement('script')
  s.type = 'text/javascript'
  s.src = 'https://unpkg.com/source-map@0.7.3/dist/source-map.js'
  head.appendChild(s)
  s.onload = () => {
    sourceMap.SourceMapConsumer.initialize({
      'lib/mappings.wasm':
        'https://unpkg.com/source-map@0.7.3/lib/mappings.wasm',
    })
  }

  initRequestLogging()
}

function Content() {
  return (
    <BrowserRouter>
      <GTMProvider state={gtmParams}>
        <ThemeProvider>
          <LanguageAutoDetect />
          <AldooAnalytics />
          <ScrollToTop />
          <CssBaseline />
          <FacebookTracking />
          <GoogleTracking />
          {/* <GTMTracking /> */}
          {/* <LogTracking /> */}
          <PageViewTracker />
          <DocumentMeta {...generateSitePageMeta()} />
          <Suspense fallback={<SuspenseLoader noCircularProgress />}>
            <Routing />
          </Suspense>
          <Suspense fallback={<SuspenseLoader noCircularProgress />}>
            <ConnectAccount />
          </Suspense>
        </ThemeProvider>
      </GTMProvider>
    </BrowserRouter>
  )
}

function App() {
  return (
    <RecoilRoot>
      {debugSupport && (
        <Suspense fallback={<SuspenseLoader noCircularProgress />}>
          <LogRender />
        </Suspense>
      )}
      <Helmet>
        <title>Aldoo - Welcome!</title>
      </Helmet>
      {debugSupport ? (
        <ErrorCapture>
          <Content />
        </ErrorCapture>
      ) : (
        <Content />
      )}
    </RecoilRoot>
  )
}

export default App
