import { Box, Stack, useTheme } from '@mui/system'
import styles from './style.css'

export default function AnimatedPlaybackIcon() {
  const theme = useTheme()
  return (
    <Box>
      <Stack
        direction={'row'}
        spacing={0.2}
        className={'barContainer'}
        style={{
          zoom: 0.5,
          display: 'flex',
        }}
        sx={{
          ml: '15px',
        }}
      >
        <svg style={{width: "10px"}} className={styles.barContainer}>
          <rect
            className={'bar1'}
            x="0"
            y="0"
            width="10"
            height="50"
            fill={theme.colors.primary.main}
          ></rect>
        </svg>
        <svg style={{width: "10px"}} className={styles.barContainer}>
          <rect
            className={'bar2'}
            x="0"
            y="0"
            width="10"
            height="50"
            fill={theme.colors.primary.main}
          ></rect>
        </svg>
        <svg style={{width: "10px"}} className={styles.barContainer}>
          <rect
            className={'bar3'}
            x="0"
            y="0"
            width="10"
            height="50"
            fill={theme.colors.primary.main}
          ></rect>
        </svg>
        <svg style={{width: "10px"}} className={styles.barContainer}>
          <rect
            className={'bar4'}
            x="0"
            y="0"
            width="10"
            height="50"
            fill={theme.colors.primary.main}
          ></rect>
        </svg>
      </Stack>
    </Box>
  )
}
