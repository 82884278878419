import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { useTranslation } from '../../utils/localization.js'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import dayjs from 'dayjs'

export default function GiftCard({ card }) {
  const { _id, created, image, status } = card
  const navigate = useNavigate()
  const t = useTranslation()
  const theme = useTheme()

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        alignItems: { xs: 'center', lg: 'flex-start' },
        backgroundColor: '#607d8b',
        justifyContent: 'flex-start',
        width: '100%',
        gap: {
          xs: '10px',
          sm: '30px',
          lg: '20px',
        },
        p: { xs: '10px', sm: '16px' },
        borderRadius: '10px',
      }}
    >
      {/* -------------------------------- GiftCard image */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: { xs: '120px', sm: '160px' },
          height: { xs: '198px', sm: '258px' },
          backgroundColor: theme.colors.aldoo.white,
          borderRadius: '10px',
          boxShadow: '1px 2px 2px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Box
          sx={{
            width: { xs: '120px', sm: '160px' },
            height: 'auto',
          }}
        >
          <img src={image} width="100%" />
        </Box>
      </Box>
      {/* -------------------------------- GiftCard details */}
      <Stack
        sx={{
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '600px',
          height: { sm: '260px' },
          gap: { xs: '16px', md: '25px' },
          py: { md: '16px' },
        }}
      >
        {/* -------------------------1st row */}
        <Stack
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'flex-end', md: 'flex-start' },
            justifyContent: { xs: 'flex-start', md: 'space-between' },
            width: '100%',
            gap: { xs: '16px', sm: '28px', md: '25px' },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: '18px', sm: '24px', md: '26px' },
              color: theme.colors.aldoo.white,
              textAlign: 'center',
            }}
          >
            {card?.metadata?.amount} {t`credits_enum`}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: '160px',
              height: '40px',
              backgroundColor: theme.colors.aldoo.white,
              borderRadius: '10px',
              px: { sm: '16px' },
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '18px', sm: '24px', md: '26px' },
                color: theme.colors.aldoo.black,
                textAlign: 'center',
              }}
            >
              {card?.metadata?.name}
            </Typography>
          </Box>
        </Stack>

        {/* -------------------------2nd row */}
        <Stack
          sx={{
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '100%',
            gap: { xs: '16px', sm: '28px', md: '25px' },
          }}
        >
          <Stack>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: '16px', md: '20px' },
                fontWeight: { xs: 700, sm: 900 },
                color: theme.colors.aldoo.white,
                textAlign: { xs: 'right', md: 'left' },
              }}
            >
              {t`Purchase date`}:
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: '16px', sm: '18px', md: '20px' },
                fontWeight: 400,
                color: theme.colors.aldoo.white,
                textAlign: { xs: 'right', md: 'left' },
              }}
            >
              {dayjs(created).format('DD/MM/YYYY')}
            </Typography>
          </Stack>

          {/* In case the card is not yet activated, show the button to activate it */}
          {status === 'free' && (
            <Button
              variant="action-color-short"
              component={RouterLink}
              to={`/gift-card?id=${_id}`}
              sx={{
                fontSize: { xs: '16px', sm: '18px' },
                ml: { xs: 'auto', md: 0 },
                '&:hover, &.Mui-selected, &.Mui-selected:hover': {
                  fontSize: { xs: '16px', sm: '18px' },
                },
              }}
            >{t`View details`}</Button>
          )}

          {/* When the card is activated show an Activated  notification */}
          {status === 'activated' && (
            <Stack
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                width: '160px',
                height: '40px',
                backgroundColor: '#122b43',
                borderRadius: '10px',
                ml: { xs: 'auto', md: 0 },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: '16px', md: '20px' },
                  fontWeight: 400,
                  color: theme.colors.aldoo.white,
                }}
              >
                {t`Activated`}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
