const fitAbbr = {
  cover: 'c',
  contain: 'a',
  fill: 'f',
  inside: 'i',
  outside: 'o'
}

export const getSuffix = (fit, lossless) => {
  let suffix = lossless ? 'l' : ''
  if (fitAbbr[fit])
    suffix += fitAbbr[fit]

  return suffix
}

export const parseWidthHeight = (crop, size) => {
  if (!crop || crop === 'default')
    return

  const [width, height] = crop.split(/x|w/)
  if (!width)
  {
    console.log(`Invalid crop: ${crop}`)
  }
  return {width: Math.round(width), height: height ? Math.round(height) : Math.round(width * size.height / size.width) }
}