import {API_URL, APP_DOMAIN} from "../config.js";
import {useLocation} from "react-router";

export function getDomain() {
    return (APP_DOMAIN ?? window.location.host)
        .replace(/^www\./gm, '')
        .replace(/:\d+$/gm, '');
}

export function getActualDomainUrl() {
    const domain = window.location.host
        .replace(/^www\./gm, '')

    if (domain.startsWith('localhost') || domain.startsWith('127.0.0.1'))
        return `http://${domain}`
    else
        return `https://${domain}`
}


export function getSubdomain() {
    const domain = getDomain();
    return domain.match(/^(.*?)\./)[1];
}

export function getApiUrl() {
    if (API_URL)
        return API_URL

    return `https://${getDomain()}`
}

export function useRootPath(levels) {
    const location = useLocation()
    if (levels === 0)
        return location.pathname

    levels = levels ?? 1

    const parts = location.pathname.split('/')
    return parts.slice(0, parts.length - levels).join('/')
}