//get the first url from the image item
const getImageURL = (item) => {
    if (!item?.default) return ''
    return item.default[0].split(' ')[0]
}

/**
 * Generate the default meta tags for the site
 * @returns
 */
export function generateBookPageMeta(book) {
    if (!book || !book.pid) return
    const contentType =
        book.bookSettings?.contentType == 'music-album' ? 'album' : 'book'
    return {
        title: 'Aldoo',
        description: 'Listen, Read, Play',
        canonical: `https://aldoo.com/${contentType}/` + book.pid.replace('book.', ''),
        meta: {
            charset: 'utf-8',
            name: {
                //keywords: 'book,aldoo,document,html,tags',
            },
            itemProp: {
                name: book.name,
                description: book.description,
                image: getImageURL(book.square),
            },
            property: {
                'og:title': book.name,
                'og:type': 'book',
                'og:image': getImageURL(book.square),
                'og:site_name': 'Aldoo',
            },
        },
    }
}

export function generateSitePageMeta() {
    return {
        title: 'Aldoo',
        description: 'Listen, Read, Play',
        canonical: 'https://aldoo.com',
        meta: {
            charset: 'utf-8',
            name: {
                //keywords: 'book,aldoo,document,html,tags',
            },
            itemProp: {
                name: "Aldoo",
                description: "Listen, Read, Play",
                image: "https://aldoo.com/static/images/aldoo.png",
            },
            property: {
                'og:title': "Aldoo",
                'og:type': 'website',
                'og:description': "Listen, Read, Play",
                'og:image': "https://aldoo.com/static/images/aldoo.png",
                'og:site_name': 'aldoo.com',
            },
        },
    }
}