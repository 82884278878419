import React, { useEffect, useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Dialog, Tab, Tabs, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import { useSearchParams } from 'react-router-dom'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import PTRestAPI from '../../utils/pt_rest_api'
import Skeleton from '@mui/material/Skeleton'
import {
  accountSelector,
  subscriptionSelector,
  useAuth,
  useLogout,
  useRefreshAccount,
  userProfileSelector,
  userPreferencesSelector,
} from 'src/utils/platform_auth'
import { Navigate, useNavigate, useParams } from 'react-router'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useTheme } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Helmet } from 'react-helmet'
import { atom, useRecoilValueLoadable, useRecoilState } from 'recoil'
import { useTranslation } from '../../utils/localization.js'
import { recoilPersist } from 'recoil-persist'
import ActiveSubscription from './activeSubscription.jsx'
import NoSubscription from './noSubscription.jsx'
import StaticImg from '../../components/StaticImg/index.jsx'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Licenses from './licenses'
import GiftCards from './giftCards'

// @User Dialog

const { persistAtom } = recoilPersist()

export const currentUserAvatar = atom({
  key: 'current-user-avatar',
  default: 'avatar_1',
  effects: [persistAtom],
})

function AvatarButton({ idx, onClick }) {
  return (
    <Button
      variant="action-white"
      onClick={() => onClick(idx)}
      sx={{
        p: {
          xs: '5px',
          lg: '10px',
          sm: '10px',
        },
        width: {
          xs: '50%',
          lg: '25%',
          sm: '25%',
        },
      }}
    >
      <StaticImg basePath={'avatars'} src={`avatar_${idx}.png`} />
    </Button>
  )
}

function ChooseAvatar({ open, onClick }) {
  const avatars = []

  for (let i = 1; i <= 130; i++)
    avatars[i - 1] = <AvatarButton key={i} idx={i} onClick={onClick} />

  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick') {
      onClick()
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          padding: '1.5rem',
          background: '#202124',
          img: {
            width: '100%',
          },
          div: {
            display: 'flex',
          },
        }}
      >
        {avatars}
      </Box>
    </Dialog>
  )
}

function Welcome({ open }) {
  const theme = useTheme()
  const navigate = useNavigate()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const t = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObj = new URLSearchParams(searchParams)

  function handleClose() {
    if (searchParamsObj.has('bookPid'))
      navigate(`/book/${searchParamsObj.get('bookPid')}`)
    else navigate('/user')
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <Box
        sx={{
          backgroundColor: theme.colors.aldoo.white,
          backgroundImage: {
            sm: 'url(/static/images/subscription-welcome-screen.png)',
          },
          backgroundSize: 'cover',
          backgroundPositionX: '65%',
        }}
      >
        <DialogContent
          sx={{
            px: {
              xs: '16px',
              sm: '30px',
            },
          }}
        >
          <Stack
            sx={{
              width: {
                xs: '100%',
                sm: '300px',
              },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box>
              <StaticImg
                src="aldoo.png"
                height="auto"
                alt="Aldoo logo"
                sx={{
                  width: {
                    xs: '250px',
                    sm: '290px',
                  },
                  objectFit: 'cover',
                  objectPosition: 'center center',
                  marginTop: '50px',
                  marginBottom: '46px',
                }}
              ></StaticImg>
            </Box>

            <Typography
              variant="h1"
              sx={{
                fontSize: '42px',
                fontWeight: 900,
                textAlign: 'left',
                mb: '40px',
              }}
            >
              {t`Welcome!`}
            </Typography>
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: {
              xs: 'center',
              sm: 'flex-start',
            },
            pl: {
              sm: '35px',
            },
          }}
        >
          <Button
            onClick={() => navigate('/catalog')}
            variant="action-color"
            sx={{
              width: {
                xs: '250px',
                sm: '290px',
              },
              border: 'none',
              mb: '50px',
            }}
          >
            {t`Continue to Catalog`}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

const pages = [
  'credits',
  'profile',
  'privacy',
  'welcome',
  'licenses',
  'gift-cards',
]

export default function User() {
  const auth = useAuth()
  const t = useTranslation()
  const { state: accountState, contents: account } = useRecoilValueLoadable(
    accountSelector,
  )

  const {
    state: subscriptionState,
    contents: subscription,
  } = useRecoilValueLoadable(subscriptionSelector)
  const [userProfile, setUserProfile] = useRecoilState(userProfileSelector)
  const [avatar, setAvatar] = useRecoilState(currentUserAvatar)
  const [avatarChoose, setAvatarChoose] = useState(false)
  const { section } = useParams()
  const logout = useLogout()
  const refreshUserAccount = useRefreshAccount()
  const nameRef = useRef()
  const [username, setUsername] = useState('')
  const [nameError, setNameError] = useState('')
  const [changeUsernameFocused, setChangeUsernameFocused] = useState(false)
  const [showAvatarChangeLabel, setShowAvatarChangeLabel] = useState(true)
  //user preferences
  const [userPreferences] = useRecoilState(userPreferencesSelector)
  const [directedMarketing, setDirectedMarketing] = useState(
    userPreferences?.direct_marketing || true,
  )

  const tab =
    section && ~pages.indexOf(section) && section !== 'welcome'
      ? section
      : 'profile'

  useEffect(() => {
    if (~avatar.indexOf('undefined')) setAvatar('avatar_1')
    if (section === 'welcome') refreshUserAccount()
    if (userProfile) setUsername(userProfile.name)
  }, [section])

  const navigate = useNavigate()

  function changeAvatar(idx) {
    if (!idx) {
      setAvatarChoose(false)
      return
    }

    ~(async () => {
      const avatar = `avatar_${idx}`
      setAvatarChoose(false)
      await PTRestAPI.ChangeUserAvatar(auth, avatar)
      setAvatar(avatar)
    })()
  }

  function changeUsername() {
    ~(async () => {
      if (!username || username == '') {
        setNameError(t`Name is required`)
        nameRef.current.focus()
        return
      }
      setChangeUsernameFocused(false)
      const saveUsername = username.trim()

      await PTRestAPI.ChangeUserName(auth, saveUsername)
      refreshUserAccount()
    })()
  }

  const logOut = () => {
    logout()
    navigate('/')
  }

  const handleChange = (event, newValue) => {
    navigate('/user/' + newValue)
  }

  const onDirectMarketingChange = (event, newValue) => {
    ~(async () => {
      setDirectedMarketing(newValue)
      await PTRestAPI.UserPreferences(auth, {
        direct_marketing: newValue,
      })
    })()
  }

  if (auth === false) {
    const redirectTo = section ? `/user/${section}` : '/user'
    return <Navigate to={`/login?redirectTo=${redirectTo}`} />
  }

  if (section && pages.indexOf(section) === -1) return <Navigate to="/user" />

  const theme = useTheme()
  return (
    <Box
      sx={{
        width: { xs: '100%', md: '90%' },
        maxWidth: '1360px',
        marginBottom: { xs: 0, sm: '10rem' },
        marginX: 'auto',
        minHeight: '30vh',
        px: { xs: '16px', md: 0 },
        pt: '44px',
        mt: '50px',
      }}
    >
      <Helmet>
        <title>{t`Aldoo - Profile Settings`}</title>
      </Helmet>
      <Welcome open={section === 'welcome'} />

      <Typography
        component="h1"
        variant="h2"
        sx={{
          textAlign: {
            xs: 'center',
            sm: 'left',
          },
          mb: '30px',
          color: theme.colors.aldoo.black,
        }}
      >
        {t`Profile Settings`}
      </Typography>

      <Box
        sx={{
          width: '100%',
          mx: 'auto',
        }}
      >
        <ChooseAvatar open={avatarChoose} onClick={changeAvatar} />
        <TabContext value={tab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              justifyItems: 'space-between',
              width: '100%',
              flexDirection: 'row',
              mb: { sm: '63px' },
            }}
          >
            <Tabs
              value={tab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab label={t`Profile`} value="profile" />
              <Tab label={t`Credits`} value="credits" />
              <Tab label={t`Gift Cards`} value="gift-cards" />
              <Tab label={t`Licenses`} value="licenses" />
              <Tab label={t`Privacy`} value="privacy" />
              {/*<Tab label={t`Your Devices`} value="devices" />*/}
            </Tabs>
          </Box>
          <TabPanel value="profile">
            <Stack
              sx={{
                alignItems: 'flex-end',
                width: '100%',
                maxWidth: '800px',
              }}
            >
              {/*----------------------------------------------------> Profile */}
              <Stack
                sx={{
                  flexDirection: { xs: 'column', sm: 'row' },
                  width: '100%',
                  maxWidth: '800px',
                  justifyContent: 'space-between',
                  gap: { sm: '60px' },
                }}
              >
                {avatar ? (
                  <Box
                    sx={{
                      width: { xs: '260px', sm: '380px' },
                      textAlign: 'center',
                      mx: {
                        xs: 'auto',
                        sm: 0,
                      },
                    }}
                  >
                    <Button
                      variant="action-white"
                      color="white"
                      onClick={() => setAvatarChoose(true)}
                      onMouseEnter={() => setShowAvatarChangeLabel(false)}
                      onMouseLeave={() => setShowAvatarChangeLabel(true)}
                    >
                      <StaticImg
                        sx={{ cursor: 'pointer' }}
                        width={'auto'}
                        height={'auto'}
                        basePath="avatars"
                        src={`${avatar || 'avatar_1'}.png`}
                      />
                    </Button>
                    <Typography
                      sx={{
                        visibility: showAvatarChangeLabel
                          ? 'visible'
                          : 'hidden',
                      }}
                    >{t`Change avatar`}</Typography>
                  </Box>
                ) : (
                  <Skeleton variant="rectangular" height="100%" />
                )}
                {userProfile ? (
                  <Stack justifyContent={'space-between'} width={'100%'}>
                    <Stack
                      direction="row"
                      gap={3}
                      sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        width={
                          changeUsernameFocused && nameError == ''
                            ? '70%'
                            : '100%'
                        }
                      >
                        <TextField
                          error={nameError !== ''}
                          inputRef={nameRef}
                          fullWidth
                          label="name"
                          size="small"
                          variant="standard"
                          margin="normal"
                          helperText={nameError}
                          onEnd
                          value={username}
                          onFocus={() => setChangeUsernameFocused(true)}
                          onChange={(e) => {
                            setNameError('')
                            setUsername(e.target.value)
                          }}
                          sx={{
                            '.MuiInputBase-input': {
                              fontSize: {
                                xs: '16px',
                                sm: '18px',
                                md: '20px',
                              },
                            },
                          }}
                        />
                      </Box>
                      {changeUsernameFocused && nameError == '' && (
                        <Box width="30%">
                          <Button
                            onClick={changeUsername}
                            variant="action-color"
                            fullWidth
                          >
                            Apply
                          </Button>
                        </Box>
                      )}
                    </Stack>
                    <TextField
                      disabled
                      label="email"
                      size="small"
                      variant="standard"
                      margin="normal"
                      value={userProfile.email}
                      sx={{
                        '.MuiInputBase-input': {
                          fontSize: {
                            xs: '16px',
                            sm: '18px',
                            md: '20px',
                          },
                        },
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack dir="vertical">
                    <Skeleton height="4rem" />
                    <Skeleton height="4rem" />
                  </Stack>
                )}
              </Stack>
              <Button
                variant="action-color"
                onClick={logOut}
                sx={{
                  width: '190px',
                  mt: '16px',
                  mx: {
                    xs: 'auto',
                    sm: 0,
                  },
                }}
              >
                {t`Logout`}
              </Button>
            </Stack>
          </TabPanel>

          <TabPanel value="credits">
            {/* -------------------------------------------------------> Credits and Subscription */}
            <Stack
              sx={{
                flexDirection: { sm: 'row' },
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                gap: { xs: '30px', md: '80px' },
              }}
            >
              {subscriptionState !== 'hasValue' ? (
                <Skeleton variant="rectangular" height="100%" width="100%" />
              ) : subscription ? (
                <ActiveSubscription subscription={subscription} />
              ) : (
                <NoSubscription />
              )}

              <Divider
                orientation="vertical"
                flexItem
                sx={{ display: { xs: 'none', sm: 'block' } }}
              />
              <Divider
                orientation="horizontal"
                flexItem
                sx={{ display: { xs: 'block', sm: 'none' } }}
              />
              {/* --------------------------------------------> Credits */}
              <Stack
                sx={{
                  width: { xs: '100%', sm: '45%' },
                  height: '280px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h4" sx={{ mb: '50px' }}>
                  {t`Available credits:`}
                </Typography>

                <Stack
                  direction="row"
                  sx={{
                    gap: '12px',
                    alignItems: 'center',
                  }}
                >
                  <StaticImg alt="aldoo-credits" src="coin.png" crop="60x60" />
                  <Typography variant="accent_number" sx={{ fontSize: '70px' }}>
                    {accountState === 'hasValue' ? account.coins : '-'}
                  </Typography>
                </Stack>

                <Stack
                  justifyContent={'flex-start'}
                  sx={{
                    alignItems: 'center',
                    gap: { xs: '8px' },
                    mt: 'auto',
                  }}
                >
                  <Button
                    href="/buy-coins"
                    variant="action-color"
                    sx={{
                      fontSize: '16px',
                    }}
                  >
                    {t`Get Aldoo Credits`}
                  </Button>
                  <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <HelpOutlineIcon
                      color="primary"
                      sx={{ width: '18px', mr: '6px' }}
                    />

                    <Tooltip
                      title={t`Aldoo credits provide access to the content on this platform. They can be recharged automatically or at your preference.`}
                    >
                      <Typography
                        variant="subtitle3"
                        color="primary"
                        fontSize={'14px'}
                      >
                        {t`What are Aldoo credits?`}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </TabPanel>

          <TabPanel
            value="gift-cards"
            sx={{
              px: {
                xs: 0,
                sm: '27px',
              },
            }}
          >
            <GiftCards />
          </TabPanel>

          <TabPanel
            value="licenses"
            sx={{
              px: {
                xs: 0,
                sm: '27px',
              },
            }}
          >
            <Licenses />
          </TabPanel>

          <TabPanel value="privacy">
            <FormGroup>
              <FormControlLabel
                control={<Switch defaultChecked />}
                checked={directedMarketing}
                onChange={onDirectMarketingChange}
                labelPlacement="end"
                label={t`direct_marketing_message`}
                componentsProps={{
                  typography: {
                    sx: { ml: '20px' },
                  },
                }}
              />
              {/* <FormControlLabel
                required
                control={<Switch />}
                label="Required"
              /> */}
            </FormGroup>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  )
}
