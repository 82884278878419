import { Stack, Typography, Button } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import QRCode from "react-qr-code"
import isMobile from "ismobilejs"

const installs = {
  apple: {
    name: "iOS",
    url: "https://apps.apple.com/app/id1550787199",
    badge: "/static/images/app_store.png",
  },
  google: {
    name: "Google Play",
    url: "https://play.google.com/store/apps/details?id=com.rilagames.patandtom",
    badge: "/static/images/google_play.png",
  },
}
export default function AppInstallGroup({ platform, badge }) {
  const theme = useTheme()
  const install = installs[platform]

  //show only the install for the correct platform
  if (
    (isMobile(window.navigator).apple.device && platform != "apple") ||
    (isMobile(window.navigator).android.device && platform != "google")
  ) {
    return <></>
  }

  return (
    <Stack style={{ background: "white", padding: "16px" }}>
      <Typography
        variant="h3"
        sx={{
          color: theme.colors.primary.main,
          mb: "16px",
        }}
      >
        {install.name}
      </Typography>
      <QRCode
        size={256}
        style={{
          zoom: 0.6,
          height: "auto",
          maxWidth: "256px",
          width: "256px",
        }}
        value={install.url}
        viewBox={`0 0 256 256`}
      />
      <Button
        variant="text"
        sx={{
          mt: "16px",
          height: "58px",
          background: `url(${install.badge})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        onClick={() => window.open(install.url, "_blank")}
      ></Button>
    </Stack>
  )
}
