//talk with the platform users
export const PLATFORM_API_URL = 'https://ab-api.aldoo.com'
export const PLATFORM_API_VERSION = 'v1'
export const STRIPE_API_KEY = 'pk_live_51KHlvMDqI0V60CRML2VsmmU7IlOLHaLEpFd6QGWtY7Y82HfApsxMSkm4C1EIzgH0xXT5puVRUo8vt1w075qxTLzC00OT57Wgj1'
export const ANALYTICS_URL = "https://cc-api.aldoo.com"

//console.log("Running PRODUCTION");
//PRODUCTION

export const FACEBOOK_PIXEL = "309325795060874"
export const FACEBOOK_OPTIONS = false
export const TRACKING_GDPR = false

export const GOOGLE_ANALYTICS_ID = "G-VVN251KHZN"
export const GTM_ID = "AW-11197013088" //"GTM-5SK3LCL"
export const gtmParams = {
    id: GTM_ID,
}
