import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useAuth } from '../../utils/platform_auth'
import { useTranslation } from '../../utils/localization.js'
import { useTheme } from '@mui/material/styles'
import { LicenseCards } from '../../utils/license-cards'
import { useState, useEffect } from 'react'
import { ANALYTICS_URL } from '../../config'
import { usePromise } from '../../utils/usePromise'
import LicenseCard from './licenseCard'
import { useNavigate } from 'react-router-dom'
import ActivateLicenseDialog from './activateLicenseDialog'
import isMobile from 'ismobilejs'

export default function Licenses() {
  const auth = useAuth()
  const [openActivate, setOpenActivate] = useState(false)

  const navigate = useNavigate()
  const { execute, result: licenses } = usePromise(
    async () => {
      //ensure there is a license first
      await LicenseCards.ensureLicense()
      return await LicenseCards.listLicenses()
    },
    true,
    false,
  )
  const t = useTranslation()
  const theme = useTheme()

  useEffect(() => {
    if (!auth) return

    LicenseCards.init({
      API_URL: ANALYTICS_URL,
      maid: 'aldoo-books',
      raid: 'aldoo-kids',
      muid: auth._id,
    })
    execute()
  }, [auth])

  if (!auth) {
    return
  }

  if (!licenses || !licenses.length) return

  const interactMessage = isMobile(window.navigator).any
    ? t`Tap to activate`
    : t`Click to activate`

  const onCardClick = () => {
    //set the lcid for the controller
    LicenseCards.lcid = licenses[0].lcid
    //if it's a sub with annual or monthly, start the card activation
    setOpenActivate(true)
  }

  return (
    <Stack
      sx={{
        flexDirection: { lg: 'row' },
        alignItems: { xs: 'center', lg: 'flex-start'},
        justifyContent: 'center',
        width: '100%',
        gap: {
          xs: '40px', 
          lg: '80px',
        },
      }}
    >
      <Stack
        sx={{
          alignItems: 'center',
        }}
      >
        <ActivateLicenseDialog
          controller={LicenseCards}
          license={licenses[0]}
          setOpen={setOpenActivate}
          open={openActivate}
        />
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '100%',
            gap: '80px',
            width: '100%',
          }}
        >
          <Button
            onClick={onCardClick}
            sx={{
              width: {
                xs: '328px',
                sm: '440px',
              },
              p: 0,
              '&:hover, &.Mui-selected, &.Mui-selected:hover': {
                transform: 'scale(1.04)',
                transition: 'transform 0.4s',
              },
            }}
          >
            <LicenseCard license={licenses[0]} />
          </Button>
        </Stack>

        {licenses[0].paired ? (
          <Typography
            variant="h4"
            component="subtitle"
            sx={{
              fontSize: '16px',
              color: theme.colors.primary.main,
              mt: '20px',
            }}
          >
            {t`License Active`}
          </Typography>
        ) : (
          <Typography
            variant="h4"
            component="subtitle"
            sx={{
              fontSize: '16px',
              color: theme.colors.alpha.black[70],
              mt: '20px',
            }}
          >
            {interactMessage}
          </Typography>
        )}
      </Stack>

      <Stack
        sx={{
          maxWidth: {
            xs: '440px',
            md: '550px',
          },
          alignItems: {
            xs: 'center', 
            lg: 'flex-start',
            height: {lg: '233px'},
          },
          justifyContent: 'flex-start',
          gap: '20px',
          pb: {
            xs: '40px',
            sm: 0,
          }
        }}
      >
        <Typography
          component="h2"
          variant="h1"
          sx={{
            fontSize: {xs: '22px', sm: '26px', lg: '30px'},
            textAlign: {xs: 'center', lg: 'left'},
            mb: '16px',
          }}
        >
          {t`License_Title`}
        </Typography>

        <Typography 
          variant="body"
          sx={{ 
            textAlign: 'justify',
            fontSize: {xs: 16, sm: 18},
            fontWeight: 400,
          }}
        >
          {t`license_line_1`}
        </Typography>

        <Typography 
          variant="body"
          sx={{ 
            textAlign: 'justify',
            fontSize: {xs: 16, sm: 18},
            fontWeight: 400,
          }}
        >
          {t`license_line_2`}
        </Typography>

      </Stack>
    </Stack>
  )
}
