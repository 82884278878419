import { lazy } from 'react'
import { Navigate } from 'react-router'
import servicePagesRoutes from './servicePages.jsx'
import { BookPlayer } from '../components/BookPlayer/index.jsx'
const ShopFront = lazy(() => import('../content/BookStoreFrontPage'))
const BookPage = lazy(() => import('../content/BookPage'))
const BookCategoryPage = lazy(() => import('../content/BookCategoryPage'))
const BookCatalogPage = lazy(() => import('../content/BookCatalogPage'))
const ProductPayment = lazy(() => import('../content/PackagePage/payment.jsx'))
const Payment = lazy(() => import('../content/Plans/payment.jsx'))
const PlansPage = lazy(() => import('../content/Plans/index.jsx'))
const PackagePage = lazy(() => import('../content/PackagePage/index.jsx'))
const LibraryPage = lazy(() => import('../content/LibraryPage/index.jsx'))
const ProductPaymentSuccess = lazy(() =>
  import('../content/PackagePage/success.jsx'),
)
const BookStoreTopBarLayout = lazy(() =>
  import('../layout/BookStoreTopBarLayout/index.jsx'),
)
const Contact = lazy(() => import('../content/Contact/index.jsx'))
const ReviewSuccessfullyAdded = lazy(() =>
  import('../components/ProductReviews/reviewSuccessfullyAdded.jsx'),
)
const LeaveReview = lazy(() =>
  import('../components/ProductReviews/leaveReview.jsx'),
)
const AboutUs = lazy(() => import('../content/AboutUs'))
const Success = lazy(() => import('../content/Plans/success.jsx'))
const HelpCenter = lazy(() => import('../content/HelpCenter/index.jsx'))
const GiftCardActivate = lazy(() => import('../content/GiftCards/activate.jsx'))
const GiftCardBuy = lazy(() => import('../content/GiftCards/Buy/index.jsx'))
const GiftCards = lazy(() => import('../content/GiftCards/index.jsx'))
const GiftCardInspector = lazy(() => import('../content/GiftCards/inspect.jsx'))
const ActivateLicenseCard = lazy(() =>
  import('../content/ActivateLicenseCard/index.jsx'),
)
const CreatorPage = lazy(() => import('../content/CreatorPage/index.jsx'))
const AldooKids = lazy(() => import('../content/AldooKids'))

const bookStore = [
  {
    path: '',
    element: <ShopFront />,
  },
  {
    path: 'about-us',
    element: <AboutUs />,
  },
  {
    path: 'aldoo-kids',
    element: <AldooKids />,
  },
  {
    path: 'library/:tab?',
    element: <LibraryPage />,
  },
  {
    path: 'catalog',
    element: <BookCatalogPage />,
  },
  {
    path: 'gift-cards',
    element: <GiftCards />,
  },
  {
    path: 'activate',
    element: <GiftCardActivate />,
  },
  {
    path: 'gift-card-buy',
    element: <GiftCardBuy />,
  },
  {
    path: 'gift-card',
    element: <GiftCardInspector />,
  },
  {
    path: 'license',
    element: <ActivateLicenseCard />,
  },
  {
    path: 'help-center',
    element: <HelpCenter />,
  },
  {
    path: 'help-center/:topic',
    element: <HelpCenter />,
  },
  {
    path: 'book/:pid',
    element: <BookPage />,
    children: [
      {
        subPage: true,
        path: 'leave-review',
        element: <LeaveReview pidPrefix="book." />,
      },
      {
        subPage: true,
        path: 'leave-review/success',
        element: <ReviewSuccessfullyAdded />,
      },
    ],
  },
  {
    path: 'album/:pid',
    element: <BookPage />,
    children: [
      {
        subPage: true,
        path: 'leave-review',
        element: <LeaveReview pidPrefix="book." />,
      },
      {
        subPage: true,
        path: 'leave-review/success',
        element: <ReviewSuccessfullyAdded />,
      },
    ],
  },
  {
    path: 'books',
    element: <BookCategoryPage allBooks />,
  },
  {
    path: 'category/:category',
    element: <BookCategoryPage />,
  },
  {
    path: '/creators',
    element: <CreatorPage />,
  },
  {
    path: '/buy-book/:pid',
    element: <ProductPayment book={true} />,
  },
  {
    path: '/buy-coins',
    element: <PackagePage />,
  },
  {
    path: '/buy-coins/success',
    element: <ProductPaymentSuccess />,
  },
  {
    path: '/buy-coins/:pid',
    element: <ProductPayment />,
  },
  {
    path: '/success',
    element: <Success />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/plans',
    element: <PlansPage />,
  },
  {
    path: '/plans/:pid',
    element: <Payment />,
  },
  ...servicePagesRoutes,
]

export default [
  {
    path: '*',
    element: <Navigate to={'/'} />,
  },
  {
    path: '',
    element: <BookStoreTopBarLayout />,
    children: bookStore,
  },
]
