import { Dialog } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { useTheme } from '@mui/material/styles'
import isMobile from 'ismobilejs'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from '../../utils/localization.js'
import { Stack, Box } from '@mui/system'
import Button from '@mui/material/Button'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useRecoilValueLoadable } from 'recoil'
import { accountSelector } from '../../utils/platform_auth'
import { Typography } from '@mui/material'
import QRCode from 'react-qr-code'
import AppInstallGroup from './appInstallGroup.jsx'
import Lottie from 'lottie-react'
import successAnim from '../../components/PurchaseConfirmDialog/success.json'

export default function ActivateLicenseDialog({
  license,
  controller,
  open,
  setOpen,
}) {
  const t = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const [step, setStep] = useState(0)
  const mobile = isMobile(window.navigator).any
  const [showDownloadLink, setShowDownloadLink] = useState(false)

  const activateUrl = window.location.origin + '/license?l=' + license.lcid

  const { contents: account } = useRecoilValueLoadable(accountSelector)
  const canActivateCard =
    account &&
    (account.account_type === 'premium-annual' ||
      account.account_type === 'premium-monthly')

  useEffect(() => {
    if (!canActivateCard) {
      setStep(1)
      return
    }

    if (license.paired) {
      setStep(4)
      return
    }

    if (open) setStep(2)
  }, [canActivateCard, open])

  //polling for activation
  useEffect(() => {
    if (step != 3) return
    const interval = setInterval(async () => {
      const res = await controller.getPairingStatus()
      if (res.paired) {
        clearInterval(interval)
        //mark the license as paired so the UI can react to this
        license.paired = true
        setStep(4)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [step])

  return (
    <Dialog open={open}>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <Stack
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            maxWidth: '600px',
            maxHeight: '500px',
            flexDirection: 'row',
            backgroundColor: theme.colors.aldoo.white,
            borderRadius: '20px',
            overflow: 'hidden',
            zIndex: 10,
          }}
        >
          <Button
            onClick={() => setOpen(false)}
            size="large"
            // variant="help-info-gray"
            sx={{
              display: ` ${step == 4 ? 'none' : 'block'}`,
              position: 'absolute',
              color: theme.colors.secondary,
              zIndex: 100,
              top: '20px',
              right: '0px',
              p: 0,
              ml: 'auto',
              mb: '120px',
            }}
          >
            <CloseOutlinedIcon fontSize="large" />
          </Button>
          {/* Step 1 Needs Subscription */}
          {step == 1 && (
            <Stack
              sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                textAlign: 'center',
              }}
              direction={'column'}
            >
              <Typography
                variant="h4"
                sx={{ color: theme.colors.primary.main }}
              >
                {t('lc_you_need_subscription')}
              </Typography>
              <Button
                onClick={() => navigate('/buy-coins')}
                variant="action-color"
                sx={{
                  color: theme.colors.secondary,
                  mt: '40px',
                }}
              >
                {t('Subscribe Now')}
              </Button>
            </Stack>
          )}
          {/* Step 2 Install The App */}
          {step == 2 && (
            <Box sx={{ width: '100%' }}>
              <Stack
                sx={{
                  width: '100%',
                  height: '100%',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                direction={'column'}
              >
                <Typography
                  variant="h4"
                  sx={{
                    mt: '50px',
                    color: theme.colors.primary.main,
                  }}
                >
                  {t`lc_first_install_the_app`}
                </Typography>

                <Stack direction={'horizontal'} gap={8}>
                  <AppInstallGroup platform="apple" />
                  <AppInstallGroup platform="google" />
                </Stack>

                <Button
                  onClick={() => setStep(3)}
                  variant="action-color"
                  sx={{
                    color: theme.colors.secondary,
                    mt: '20px',
                  }}
                >
                  {t`lc_i_have_the_app`}
                </Button>
              </Stack>
            </Box>
          )}
          {/* Step 3 Activate License  */}
          {step == 3 && (
            <Box sx={{ width: '100%' }}>
              <Stack
                sx={{
                  width: '100%',
                  height: '100%',
                  // justifyContent: 'space-evenly',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                direction={'column'}
              >
                <Typography
                  variant="h4"
                  sx={{
                    mt: '50px',
                    color: theme.colors.primary.main,
                  }}
                >
                  {t`Activate License`}
                </Typography>

                <Stack
                  sx={{
                    mt: '50px',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                  direction={'column'}
                >
                  <QRCode
                    size={256}
                    style={{
                      zoom: 0.6,
                      height: 'auto',
                      maxWidth: '256px',
                      width: '256px',
                    }}
                    value={activateUrl}
                    viewBox={`0 0 256 256`}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      mt: '40px',
                      px: '20px',
                    }}
                  >
                    {t`Scan the QR Code with your Mobile device`}
                  </Typography>
                  {mobile && (
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          my: '20px',
                        }}
                      >
                        {t`or tap below`}
                      </Typography>
                      <Button
                        onClick={() => window.open(activateUrl, '_blank')}
                        variant="action-color"
                        sx={{
                          color: theme.colors.secondary,
                        }}
                      >
                        {t`lc_activate`}
                      </Button>
                    </Box>
                  )}
                </Stack>
              </Stack>
            </Box>
          )}
          {step == 4 && (
            <Box sx={{ width: '100%' }}>
              <Stack
                sx={{
                  width: '100%',
                  height: '100%',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
                direction={'column'}
              >
                <Typography
                  variant="h4"
                  sx={{ color: theme.colors.primary.main }}
                >
                  {t`lc_your_license_is_now_active`}
                </Typography>

                {!showDownloadLink && (
                  <Lottie
                    style={{
                      width: '250px',
                      height: '300px',
                    }}
                    animationData={successAnim}
                    loop={false}
                    onComplete={() => {
                      setShowDownloadLink(true)
                    }}
                  />
                )}
                {showDownloadLink && (
                  <Stack direction={'horizontal'} gap={8}>
                    <AppInstallGroup platform="apple" />
                    <AppInstallGroup platform="google" />
                  </Stack>
                )}
                <Typography sx={{ px: '20px' }} variant="body1">
                  {t`lc_you_can_now_play_on_your_device`}
                </Typography>
                <Button
                  onClick={() => {
                    setShowDownloadLink(false)
                    setOpen(false)
                  }}
                  variant="action-color"
                  sx={{
                    mt: '10px',
                    color: theme.colors.secondary,
                  }}
                >
                  {t`Ok`}
                </Button>
              </Stack>
            </Box>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
