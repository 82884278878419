import { useTranslation } from '../../utils/localization.js'
import React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@mui/material/styles'
import SettingsIcon from '@mui/icons-material/Settings'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { recoilPersist } from 'recoil-persist'
import { atom, useRecoilState } from 'recoil'
import { LightTooltip } from '../LightTooltip/index.jsx'
import { useRef } from 'react'
const { persistAtom } = recoilPersist()


export const playerSettingsAtom = atom({
  key: 'player-settings',
  default: {
    audio: true,
    autoPlay: true,
    subtitles: true,
    volume: 100,
  },
  effects: [persistAtom],
})
export function PlayerSettingsButton({ isFullscreen }) {
  const t = useTranslation()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const [settings, setSettings] = useRecoilState(playerSettingsAtom)
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const anchorRef = useRef()

  const handleClick = (event) => {
    setAnchorEl(anchorRef.current)
    setTooltipOpen(false)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const switchFor = (key) => ({
    checked: settings[key],
    onClick: () =>
      setSettings({
        ...settings,
        [key]: !settings[key],
      }),
  })

  const menuPosition = isFullscreen
    ? {
        vertical: -200,
        horizontal: -180,
      }
    : {
        vertical: -230,
        horizontal: "center",
      }

  return (
    <div
      sx={{
        position: 'relative',
      }}
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      <LightTooltip
        title={t`Settings`}
        placement="top"
        enterDelay={500}
        leaveDelay={200}
        open={tooltipOpen}
      >
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="action-white"
          color="white"
          ref={anchorRef}
        >
          <SettingsIcon
            sx={{
              fontSize: '30px',
            }}
          />
        </Button>
        <Menu
          id="basic-menu"
          variant="playerSettings"
          anchorEl={anchorEl}
          anchorOrigin={menuPosition}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            // position: 'absolute',
            // top: '-90px',
            '& .MuiMenu-paper': {
              backgroundColor: theme.colors.alpha.black[70],
            },
          }}
        >
          <MenuItem>
            <FormGroup
              sx={{
                gap: '20px',
                '& .MuiFormControlLabel-label': {
                  color: theme.colors.aldoo.white,
                  fontWeight: 700,
                },
              }}
            >
              <FormControlLabel
                control={<Switch {...switchFor('subtitles')} />}
                label={t`Subtitles`}
              />
              <FormControlLabel
                control={<Switch {...switchFor('autoPlay')} />}
                label={t`Read to me`}
              />
              <FormControlLabel
                control={<Switch {...switchFor('audio')} />}
                label={t`Audio`}
              />
            </FormGroup>
          </MenuItem>
        </Menu>
      </LightTooltip>
    </div>
  )
}
