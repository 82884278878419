import { useEffect, useRef } from 'react'
import { useLocation, matchRoutes } from 'react-router'
import { useRouting } from '../../content/Routing.jsx'
import findLast from 'lodash/findLast.js'
import { scrollToTop } from '../../utils/dom.js'

export function ScrollToTop() {
  const location = useLocation()
  const routing = useRouting()
  const matches = matchRoutes(routing, location)

  const lastNonSubpage = findLast(matches, (match) => !match.route.subPage)

  useEffect(() => {
    scrollToTop()
  }, [lastNonSubpage?.pathname])

  return null
}
